import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import { useLocation } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import ClipLoader from "react-spinners/ClipLoader";
import StripeCheckout from 'react-stripe-checkout';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import checkoutForm from './checkoutForm'; 
import axios from 'axios';


function Paymentsuccespage(){
 const mydata = localStorage.getItem('')
  // const baseUrl = "http://localhost:9500/api/createOrder";
  const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/createOrder";
  const MySwal = withReactContent(Swal)
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const PostData = localStorage.getItem('justgympostdataorder');
  const storedPostData = JSON.parse(PostData);





const createOrder = async () => {
  if (!storedPostData) {
    return MySwal.fire('Error in recovering information');
  } else {
    try {

      console.log(storedPostData)

      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: async () => {
          try {
            const response = await axios.post(baseUrl, storedPostData, axiosConfig);
            console.log("response received:", response);
            Swal.fire({
              text: response.data.data,
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Back to Shop",
              closeOnCancel: true,
            }).then((result) => {
              console.log(response);
              window.location.href = 'shop';
              localStorage.removeItem('mygymfitnesscartitem');
              localStorage.removeItem('justgympostdataorder');
              localStorage.removeItem('mygymfitnesscartcartitemid');
              localStorage.removeItem('mygymfitnesscart');
            });
          } catch (error) {
            console.log("AXIOS ERROR:", error);
            Swal.fire(error.message || "An error occurred");
          }
        },
      });
    } catch (error) {
      console.error('Error creating order:', error);
    }
  }
};

useEffect(() => {
    createOrder();
  }, []);




  
  

      return (
     <div>
     <Header/>
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Paymentsuccespage;
