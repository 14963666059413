import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './component/home';
import Shop from './component/shop';
import About from './component/about';
import Cart from './component/cart';
import Contact from './component/contact';
import Checkout from './component/checkout';
import Register from './component/register';
import Singleproduct from './component/singleproduct';
import Admindash from './component/admindash';
import Faq from './component/faq';
import Paymentsuccespage from './component/paymentsuccespage';
import Errorpage from './component/errorpage';
import Privacy from './component/privacy';
import Customers from './component/customers';
import Items from './component/items';
import Orders from './component/orders';



export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/shop" component={Shop}/>
          <Route exact path="/singleproduct" component={Singleproduct}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/cart" component={Cart}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/checkout" component={Checkout}/>
          <Route exact path="/admindash" component={Admindash}/>
          <Route exact path="/privacy" component={Privacy}/>
          <Route exact path="/customers" component={Customers}/>
          <Route exact path="/items" component={Items}/>
          <Route exact path="/orders" component={Orders}/>
          <Route exact path="/faq" component={Faq}/>
          <Route exact path="/paymentsuccespage" component={Paymentsuccespage}/>
          <Route exact path="/errorpage" component={Errorpage}/>
          {/* <Route path="/signin" component={SignIn1}/>
          <Route path="/Signup" component={Signup}/>
          <Route path="/newinvoice" component={Newinvoice}/>
          <Route path="/taxreturn" component={Taxreturn}/>
          <Route path="/newreceipt" component={Newreceipt}/>
          <Route path="/newquote" component={Newquote}/>
          <Route path="/allreceipts" component={Allreceipts}/>
          <Route path="/allquotes" component={Allquotes}/>
          <Route path="/payments" component={Payments}/>
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/logout" component={logout}/> */}
          {/* <Route path="/taxfile" component={Changepassword}/> */}
          {/* <Route path="/payments" component={Payments}/> */}

         
            
          
        </Switch>
      </div>
    </Router>
  );
}



