import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Register(){
    const MySwal = withReactContent(Swal)
    const [activeTab, setActiveTab] = useState(true);
  const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/register";
  const loginUrl = "https://justgymbackend.starlightsoftware.co.bw/api/login";

    const [state , setState] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        loginemail: '',
        loginpassword: ''
    })

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }

      var postData = {
        "email": state.email,
        "password": state.password,
        "firstname": state.firstname,
        "lastname": state.lastname,
        "phonenumber": state.phone,
        "role": "customer"
    };

    var postloginData = {
        "email": state.loginemail,
        "password": state.loginpassword,
    };

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
    
      const handleRegSubmit = (e) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isValidEmail = (email) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        };
        if (!state.firstname || !state.lastname|| !state.password|| !state.email|| !state.phone){
          MySwal.fire({
            title: <p>Please fill all required fields</p>,
            didOpen: () => {
            },
          }).then(() => {})
        }else if(!isValidEmail(state.email)) {
          MySwal.fire({
              title: <p>Email is invalid</p>,
              didOpen: () => {},
            }).then(() => {});
      
          }else if(!passwordRegex.test(state.password)) {
            MySwal.fire({
                title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
                didOpen: () => {},
              }).then(() => {});
        }else{
            console.log(postData)
            MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading()
            axios.post(baseUrl, postData, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
              Swal.close()
              Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  closeOnCancel: true,
                }).then(result => {
                  console.log(response);
                  setActiveTab(false);
                })
            })
            .catch((err) => {
              Swal.close();
              //toast
              toast('An error occured',{
                style:{
                  color:'white',
                  background:'red'
                }
              })
              console.log("AXIOS ERROR: ", err);
              //return MySwal.fire(err.response.data)
            })
          },
        }).then(() => {
        })
        }
      }

      const handleLoginSubmit = (e) => {
        if (!state.loginemail || !state.loginemail){
          MySwal.fire({
            title: <p>Please fill all fields</p>,
            didOpen: () => {
            },
          }).then(() => {
          })
        }else{
          MySwal.fire({
            title: <p>please wait...</p>,
            didOpen: () => {
              MySwal.showLoading()
              axios.post(loginUrl, postloginData, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
              Swal.close()
                localStorage.setItem("justgymfitnesstokenize", response.data.data.accessToken);
                localStorage.setItem("justgymfitnessfname", response.data.data.firstname);
                localStorage.setItem("justgymfitnessfi", response.data.data.id);
                localStorage.setItem("justgymfitnessrole", response.data.data.role);
                if(response.data.data.role == 'customer'){
                window.location.href="/";
                }else if(response.data.data.role == 'admin'){
                    window.location.href="admindash";
                }
                
              })
              .catch((err) => {
                Swal.close()
                console.log("AXIOS ERROR: ", err);
                return MySwal.fire(err.response.data.message)
              })
            },
          }).then(() => {
          })
        }
      }


      return (
              <div>
                <ToastContainer />
     <Header/>
     <div className="login-register-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 ml-auto mr-auto">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <a className={ activeTab ? '' : 'active'} data-bs-toggle="tab" href="#lg1">
                    <h4>login</h4>
                  </a>
                  <a data-bs-toggle="tab" className={ activeTab ? 'active' : ''}  href="#lg2">
                    <h4>register</h4>
                  </a>
                </div>
                <div className="tab-content">
                  <div id="lg1" className= {`tab-pane ${activeTab ? '' : 'active'}`}>
                    <div className="login-form-container">
                      <div className="login-register-form">
                          <input type="text" value={state.loginemail} onChange={handleChange} id="loginemail" name="loginemail" placeholder="email" />
                          <input type="password" value={state.loginpassword} onChange={handleChange} id="loginpassword" name="loginpassword" placeholder="Password" />
                          <div className="button-box">
                            <div className="login-toggle-btn">
                              <input type="checkbox" />
                              <a className="flote-none" href="javascript:void(0)">Remember me</a>
                              {/* <a href="#">Forgot Password?</a> */}
                            </div>
                            <button onClick={handleLoginSubmit}><span>Login</span></button>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div id="lg2" className= {`tab-pane ${activeTab ? 'active' : ''}`}>
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <input type="text" placeholder="Firstname" value={state.firstname} onChange={handleChange} id="firstname" name="firstname" autoComplete="off" />
                        <input type="text" placeholder="Lastname" value={state.lastname} onChange={handleChange} id="lastname" name="lastname" autoComplete="off" />
                        <input type="text" placeholder="Email" value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" />
                        <input type="text" placeholder="phone" value={state.phone} onChange={handleChange} id="phone" name="phone" autoComplete="off" />
                        <input type="text" placeholder="password" value={state.password} onChange={handleChange} id="password" name="password" autoComplete="off" />
                          <div className="button-box">
                            <button onClick={handleRegSubmit} ><span>Register</span></button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default Register;
