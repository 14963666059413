import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import { useLocation } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import ClipLoader from "react-spinners/ClipLoader";
import StripeCheckout from 'react-stripe-checkout';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import checkoutForm from './checkoutForm'; 
import axios from 'axios';
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
  const stripePromise = loadStripe('pk_live_51O9nh8G0Pxe9yfqZoeYyRZa7HYrXonlqSPJYe2RwLK8JCfbBKPlt4ivKh4Dz5ePQd37wDs6sno201hmolKNkjwYC00VLEKbN4y');
  // const stripePromise = loadStripe('pk_test_51JDvPcExCELSjEQdzbBRXkXzRIspi0jRO7qU0vwOjEAghJbACPv91shKsDdi7H2uLA9kWwpLV83qPKQWrSFuAyCV00fvcWHl5L');




function Checkout(){
  const [isFormValid, setIsFormValid] = useState(false);
  const [isDiscountValid, setDiscountValid] = useState(false);
  
  const location = useLocation();
  let totalValueSum = 0;
  let quantitySum = 0;
  const [sessionId, setSessionId] = useState(null);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("orange");
  const [state , setState] = useState({
    phone: '',
    email: '',
    postcode: '',
    town: '',
    address: '',
    country: '',
    addressstate: '',
    firstname: '',
    lastname: '',
    discountcode: ''
  })
  

  // const baseUrl = "http://localhost:9500/api/createOrder";
  const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/createOrder";

  


  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  const MySwal = withReactContent(Swal)
  const itemsUrl = "https://justgymbackend.starlightsoftware.co.bw/api/getCartItems";
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

 

  const IDs = JSON.parse(localStorage.getItem('mygymfitnesscartcartitemid')) || [];
  let cartitems = JSON.parse(localStorage.getItem('mygymfitnesscartitem'));
    const body = {
        itemIds: IDs, // Pass the array directly
    };
    // console.log(body)
    const [item_info, setitem] = useState([]);
    // const [quantity, setquantity] = useState('1');

    const getitemsinfo = async (e) => {
      axios.post(itemsUrl, body, axiosConfig)
        .then((response) => {
          
          // console.log("response received: ", response);
          if (!response || !Array.isArray(response.data.data)) {
            // console.log(response);
            setitem(response.data.items);
            setLoading(false)
          } else {
            // console.log(response);
            setLoading(false)
            setitem([]);
            // setitem(response.data.items);
          }
        });
    };

    const items = item_info.map((item, index) => {
      const cartItem = cartitems ? cartitems.find(cartItem => cartItem.id === item.id) : '';
      const quantity = cartItem ? cartItem.quantity : 1;
      totalValueSum += Number(item.price) * quantity;
      quantitySum += quantity;
  
      return (
        <li key={index}>
          <span className="order-middle-left">{item.itemName} X {quantity}</span>
          <span className="order-price">£{(Number(item.price) * quantity).toFixed(2)}</span>
        </li>
      );
    });

    const amountValue = item_info.map((item, index) => {
      const cartItem = cartitems ? cartitems.find(cartItem => cartItem.id === item.id) : '';
      const quantity = cartItem ? cartItem.quantity : 1;
      const totalPrice = Number(item.price) * quantity; // Calculate total price
      
      // Add total price to total value sum
      totalValueSum += totalPrice;
      
      // Return total price for each item
      return totalPrice;
    });

    // Use reduce to calculate the total value sum
      const mytotalValueSum = item_info.reduce((accumulator, item) => {
        const cartItem = cartitems ? cartitems.find(cartItem => cartItem.id === item.id) : '';
        const quantity = cartItem ? cartItem.quantity : 1;
        const totalPrice = Number(item.price) * quantity; // Calculate total price
        
        // Add total price to accumulator
        return accumulator + totalPrice;
      }, 0); // Initialize accumulator to 0

      // Now, the totalValueSum should contain the correct total value sum
      console.log(mytotalValueSum);

    const redirectToCheckout = async (sessionId) => {
      const stripe = await stripePromise;
      Swal.fire({
        title: 'Redirecting to Checkout...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });
      Swal.close();
    
      if (error) {
        console.error('Error redirecting to Checkout:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while redirecting to Checkout!',
        });
      }
    };




  const sessionUrl = "https://justgymbackend.starlightsoftware.co.bw/api/paymentsession";
  // const sessionUrl = "http://localhost:9200/api/paymentsession";
  const fullamount = totalValueSum
 

    const onToken = (token) => {
      // fetch('/save-stripe-token', {
      //   method: 'POST',
      //   body: JSON.stringify(token),
      // }).then(response => {
      //   response.json().then(data => {
      //     alert(`We are in business, ${data.email}`);
      //   });
      // });
  
      console.log(token)
    }


let mydiscountcode = 'gymfitness'


    useEffect(() => {
      getitemsinfo();
    }, [IDs, location.pathname]);



    const completeOrder = async () => {
      if (!state.firstname||!state.lastname||!state.email||!state.phone||!state.postcode||!state.town||!state.address||!state.country||!state.addressstate||!totalValueSum) {
            return MySwal.fire('Please fill all required fields')
            setIsFormValid(false)
            }else{
              const orderData = item_info.map((item, index) => {
                const cartItem = cartitems ? cartitems.find((cartItem) => cartItem.id === item.id) : '';
                const quantity = cartItem ? cartItem.quantity : 1;
        
                return {
                  item_name: item.itemName,
                  item_price: item.price,
                  quantity: quantity,
                  subtotal: Number(item.price) * quantity,
                };
              });

              var postData = {
                "OrdertotalQuantity": quantitySum,
                "Orderinfo": JSON.stringify(orderData),
                "OrdertotalAmount": Number(calculatedFinalAmount),
                // "OrdertotalAmount": (Number(totalValueSum)+ Number(5))+(Number(totalValueSum)+ Number(5))*Number(0.2),
                "PaymentStatus": 1,
                "customerpostcode": state.postcode,
                "customertown": state.town,
                "customerstate": state.addressstate,
                "itemQuantity": quantitySum,
                "customerFirstName": state.firstname,
                "customerLastName": state.lastname,
                "customerAddress": state.address,
                "customerEmail": state.email,
                "customerPhone": state.phone,
                "discountcode": state.discountcode
              };
               const voucercodevalid = sessionStorage.getItem('justgymfitnessdicountcodeapplied') 
              if (state.discountcode && state.discountcode == mydiscountcode && !voucercodevalid) {
                Swal.fire({
                  icon: 'success',
                  text: '15% discount code applied',
                }); 
               setDiscountValid(true)
               sessionStorage.setItem('justgymfitnessdicountcodeapplied', true)
               console.log(postData)
               const postDataString = JSON.stringify(postData);
               localStorage.setItem('justgympostdataorder', postDataString);
                setIsFormValid(true)
              } else{
                console.log(postData)
                const postDataString = JSON.stringify(postData);
                localStorage.setItem('justgympostdataorder', postDataString);
                 setIsFormValid(true)
              }
             
            }
          }


    const handleToken = (token, addresses) => {
      console.log('Token:', token);
      console.log('Addresses:', addresses);
      // Call your server to verify payment
      // Example: fetch('/your-server-endpoint', { method: 'POST', body: { token: token.id }})
      // Then on the server side, use your Stripe secret key to confirm the payment status
    };

    const final_amount = () => {
      let myfinal_amount = '';
      if (mytotalValueSum >= 70) {
          // myfinal_amount = (Number(mytotalValueSum) + Number(mytotalValueSum) * 0.2).toFixed(2);
          myfinal_amount = (Number(mytotalValueSum)).toFixed(2);
      } else {
          // myfinal_amount = (Number(mytotalValueSum) + 5 + (Number(mytotalValueSum) + 5) * 0.2).toFixed(2);
          myfinal_amount = (Number(mytotalValueSum) + 4.99).toFixed(2);
      }
      return myfinal_amount;
  };

  const calculatedFinalAmount = final_amount();

  const sessionbody = {
    amount: Math.round(parseFloat(calculatedFinalAmount) * 100), 
    // amount: ((Number(totalValueSum)+ Number(5))+(Number(totalValueSum)+ Number(5))*Number(0.2))*100
  };

    const createCheckoutSession = async (e) => {
      e.preventDefault();
      console.log(sessionbody)
      axios.post(sessionUrl, sessionbody, axiosConfig)
        .then((response) => {
          setLoading(false)
          console.log("response received: ", response);
          if (response) {
            const newSessionId = response.data.id;
            console.log(sessionId);
            console.log(response.data.id);
            redirectToCheckout(newSessionId);
          } else {
            console.log('no data');
            setLoading(false)
          }
        });
    };


  
  

      return (
     <div>
     <Header/>
     <div className="checkout-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="billing-info-wrap">
                <h3>Shipping Details</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>First Name</label>
                      <input className="billing-address" type="text" value={state.firstname} onChange={handleChange} id="firstname" name="firstname" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Last Name</label>
                      <input className="billing-address" type="text" value={state.lastname} onChange={handleChange} id="lastname" name="lastname" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Email</label>
                      <input className="billing-address" type="text" value={state.email} onChange={handleChange} id="email" name="email" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Phone</label>
                      <input className="billing-address" type="text" value={state.phone} onChange={handleChange} id="phone" name="phone" />
                    </div>
                  </div>
                 
                  <div className="col-lg-12">
                    <div className="billing-select mb-4">
                      <label>Country</label>
                      <select  type="text" value={state.country} onChange={handleChange} id="country" name="country">
                        <option>Select a country</option>
                        <option value={'United Kingdom'}>United Kingdom</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="billing-info mb-4">
                      <label>Street Address</label>
                      <input className="billing-address" type="text" value={state.address} onChange={handleChange} id="address" name="address" />
                      {/* <input type="text" placeholder="Apartment, suite, unit etc."  value={state.apartment} onChange={handleChange} id="apartment" name="apartment" /> */}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="billing-info mb-4">
                      <label>Town / City</label>
                      <input type="text"  value={state.town} onChange={handleChange} id="town" name="town" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>State / County</label>
                      <input type="text"  value={state.addressstate} onChange={handleChange} id="addressstate" name="addressstate" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-4">
                      <label>Postcode / ZIP</label>
                      <input type="text"   value={state.postcode} onChange={handleChange} id="postcode" name="postcode" />
                    </div>
                  </div>
                </div>
                
                <div className="checkout-account-toggle open-toggle2 mb-30">
                  <input placeholder="Email address" type="email" />
                  <input placeholder="Password" type="password" />
                  <button className="btn-hover checkout-btn" type="submit">register</button>
                </div>
                
                <div className="checkout-account mt-25">
                  {/* <input className="checkout-toggle w-auto h-auto" type="checkbox" /> */}
                  {/* <label>Ship to a different address?</label> */}
                </div>
                
              </div>
            </div>
            <div className="col-lg-5 mt-md-30px mt-lm-30px ">
              <div className="your-order-area">
                <h3>Your order</h3>
                <div className="your-order-wrap gray-bg-4">
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>Product</li>
                        <li>Total</li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                      <ClipLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={70}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                     
                   {items}
                  
                      </ul>
                    </div>
                    <div className="your-order-bottom">
                      <ul>
                        <li className="your-order-shipping">Shipping</li>
                        <li>{mytotalValueSum >=70? 'FREE': '£4.99' }</li>
                      </ul>
                      <br/>
                      <ul>
                        <li className="your-order-shipping">Voucher Code</li>
                        <input type="text" style={{width: '150px', borderRadius: '20px'}}  value={state.discountcode} onChange={handleChange} id="discountcode" name="discountcode" />
                      </ul>
                    </div>
                    <div className="your-order-total">
                      <ul>
                        <li className="order-total">Total</li>
                        {isDiscountValid ? (
                        <li>{(calculatedFinalAmount - (calculatedFinalAmount * Number(0.15))).toFixed(2)}</li>
                  ) : (
                    <p>
                      <li>{calculatedFinalAmount}</li>
                    </p>
                  )}
                        
                      </ul>
                    </div>
                  </div>
                  <div className="payment-method">
                    <div className="payment-accordion element-mrg">
                      <div id="faq" className="panel-group">
                        <div className="panel panel-default single-my-account m-0">
                          <div className="panel-heading my-account-title">
                            <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-1" className="collapsed" aria-expanded="true">Debit/Credit Card payments</a>
                            </h4>
                          </div>
                          <div id="my-account-1" className="panel-collapse collapse show" data-bs-parent="#faq">
                            <div className="panel-body">
                            <img  src='assets/images/payments.png'/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  <br/>
                  <br/>
                  <br/>
                  {/* <StripeCheckout
                    token={handleToken}
                        className='btn btn-primary'
                    stripeKey="pk_test_51JDvPcExCELSjEQdzbBRXkXzRIspi0jRO7qU0vwOjEAghJbACPv91shKsDdi7H2uLA9kWwpLV83qPKQWrSFuAyCV00fvcWHl5L" // Replace with your actual Stripe public key
                    name="JustGymFitness"
                    description="Payment for Items"
                    amount={totalValueSum * 100} // Convert to cents
                    currency="GBP"
                    billingAddress
                    shippingAddress
                  >
                    <button>Proceed to Checkout</button>
                  </StripeCheckout> */}
                  
                <div className='row'>
               
                <div className='col-lg-6'>
                  <button onClick={completeOrder} className='btn btn-primary'>Complete Order</button>
                </div>

                <div className='col-lg-6'>
                  {isFormValid ? (
                    <div>
                      <button onClick={createCheckoutSession} className='btn btn-primary'>Pay Now</button>
                      {sessionId && (
                        <Elements stripe={stripePromise}>
                          <checkoutForm sessionId={sessionId} />
                        </Elements>
                      )}
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Checkout;
