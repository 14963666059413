import React, {useState,useEffect,CSSProperties} from 'react';
import Swal from 'sweetalert2';
import ClipLoader from "react-spinners/ClipLoader";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from 'axios';
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



function Cart(){
  // const itemsUrl = "http://localhost:9200/api/getCartItems";
  const MySwal = withReactContent(Swal);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("orange");
  const itemsUrl = "https://justgymbackend.starlightsoftware.co.bw/api/getCartItems";
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const IDs = JSON.parse(localStorage.getItem('mygymfitnesscartcartitemid')) || [];
  const checkout = async (e) => {
    if(item_info.length!=0){
      await getitemsinfo();
      window.location.href = 'checkout'
    }
  }

  const [state, setState] = useState({
    myquantity: '',
  })

  let cartitems = JSON.parse(localStorage.getItem('mygymfitnesscartitem'));
  // console.log(cartitems);


  const clearcart = (e) => {
    localStorage.removeItem('mygymfitnesscartitem');
    localStorage.removeItem('mygymfitnesscartcartitemid');
    localStorage.setItem('mygymfitnesscart', '0');
    window.location.reload(false)
    // return MySwal.fire('Cart cleared')
  }

  
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myidd');

    const body = {
      itemIds: IDs, // Pass the array directly
    };
    // console.log(body)
    const [item_info, setitem] = useState([]);
    // const [quantity, setquantity] = useState('1');

    const getitemsinfo = async (e) => {
      if(IDs.length==0){setLoading(false); return}
      // e.preventDefault();
      axios.post(itemsUrl, body, axiosConfig)
        .then((response) => {
          setLoading(false)
          // console.log("response received: ", response);
          if (!response || !Array.isArray(response.data.data)) {
            // console.log(response);
            setitem(response.data.items);
          } else {
            console.log(response);
            setitem([]);
            setLoading(false)
          }
        }).catch((res)=>{
          setLoading(false)
        })
    };

    const totalValue = item_info.reduce((total, product) => {
      const productPrice = Number(product.price);
      return total +  productPrice;
    }, 0);

    const [cart, setCart] = useState(() => {
      const initialCart = item_info.map((product) => ({
        ...product,
        quantity: 0, // Set the initial quantity to 0 or another default value
      }));
      return initialCart;
    });

    const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
    }

 

    const handleRemoveItem = (itemId) => {
      // Find the item to be removed from the cartitems state
      const removedItem = cartitems.find((item) => item.id === itemId);
    //console.log(itemId)
      if (removedItem) {
        // Calculate the quantity of the removed item
        const removedQuantity = removedItem.quantity;
    
        // Filter out the item to be removed from the cartitems state
        const updatedCartItems = item_info.filter((item) => item.id !== itemId);

        // Update the local storage array 'IDs' by removing the itemId
        const updatedIDs = IDs.filter((id) => id !== itemId);
        localStorage.setItem('mygymfitnesscartcartitemid', JSON.stringify(updatedIDs));
    
        // Update the cartitems state
        setitem(updatedCartItems);
    
        // Update the count of cart items in local storage by subtracting the removed quantity
        const currentItemCount = Number(localStorage.getItem('mygymfitnesscart')) || 0;
        const updatedItemCount = Math.max(currentItemCount - removedQuantity, 0);
        // localStorage.setItem('mygymfitnesscart', updatedItemCount.toString());
        localStorage.setItem('mygymfitnesscart', updatedCartItems.length);
    
        // Reload the page (optional)
      }
    };
    
  
  
  useEffect(() => {
    getitemsinfo();
   // console.log(item_info)
  }, []);



  let totalValueSum = 0;
  let quantitySum = 0;
 


      return (
     <div>
     <Header/>
     <div className="cart-main-area pt-100px pb-100px">
        <div className="container">
          <h3 className="cart-page-title">Your cart items</h3>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <form action="#">
                <div className="table-content table-responsive cart-table-content">
                  <table>
                  <ClipLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Until Price</th>
                        <th>Qty</th>
                        <th>Subtotal</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                    {item_info.map((item, index) => {
                        const cartItem = cartitems? cartitems.find(cartItem => cartItem.id === item.id) : '';
                        const quantity = cartItem ? cartItem.quantity : 1;
                        totalValueSum += Number(item.price) * quantity;
                        quantitySum += quantity;
                        // console.log(totalValueSum)
                        // console.log(quantitySum)
                    return (
                      <tr key={index}>
                        <td className="product-thumbnail">
                          <a href="#"><img className="img-responsive ml-15px" style={{width:'50%'}} src={item.picture1} alt={item.itemName} /></a>
                        </td>
                        <td className="product-name"><a href="#">{item.itemName}</a></td>
                        <td className="product-price-cart"><span className="amount">£{item.price}</span></td>
                        <td className="product-quantity">
                          <div className="cart-plus-minus">
                            <input className="cart-plus-minus-box" type="text" onChange={handleChange}  id='myquantity' name="myquantity" disabled value={quantity} />
                          </div>
                        </td>
                        <td className="product-subtotal">£{(Number(item.price) * quantity).toFixed(2)}</td>
                        <td className="product-remove">
                          <a href="#" onClick={() => handleRemoveItem(item.id)}><i className="fa fa-times" /></a>
                        </td>
                      </tr>
                    );
                  })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="cart-shiping-update-wrapper">
                      <div className="cart-shiping-update">
                        <a href="/shop">Continue Shopping</a>
                      </div>

                      <div className="cart-clear">
                        {/* <button onClick={updatecart}>Update Shopping Cart</button> */}
                        <a href="#" onClick={clearcart}>Clear Shopping Cart</a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row">
               
                <div className="col-lg-4 col-md-12 mt-md-30px">
                  <div className="grand-totall">
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">Cart Total</h4>
                    </div>
                    <h5>Total products <span>{item_info.length}</span></h5>
                    <h4 className="grand-totall-title">Grand Total <span>{Intl.NumberFormat('en-Us',{style:'currency',currency:'GBP'}).format(Number(totalValueSum))}</span></h4>
                    <a onClick={checkout} href="#">Proceed to Checkout</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <br/>
    <br/>
    <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Cart;
