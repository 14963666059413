import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Contact(){
      return (
     <div>
     <Header/>
     <div className="contact-area pt-100px pb-100px">
        <div className="container">
          <div className="contact-wrapper">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-info">
                 
                  <div className="single-contact">
                    <div className="icon-box">
                      <img src="assets/images/icons/5.png" alt="" />
                    </div>
                    <div className="info-box">
                      <h5 className="title">Email:</h5>
                      <p><a href="mailto:demo@example.com">info@justgymfitness.com</a></p>
                    </div>
                  </div>
                  <div className="single-contact">
                    <div className="icon-box">
                      <img src="assets/images/icons/6.png" alt="" />
                    </div>
                    <div className="info-box">
                      <h5 className="title">Address:</h5>
                      <p>41 Vicarfield Street, Glasgow. UK</p>
                      <p>G51 2DU</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="contact-form">
                  <div className="contact-title mb-30">
                    <h2 className="title" data-aos="fade-up" data-aos-delay={200}>Leave a Message</h2>
                    <p>Enter information below</p>
                  </div>
                  <form className="contact-form-style" id="contact-form" action="#" method="post">
                    <div className="row">
                      <div className="col-lg-6" data-aos="fade-up" data-aos-delay={200}>
                        <input name="name" placeholder="Name*" type="text" />
                      </div>
                      <div className="col-lg-6" data-aos="fade-up" data-aos-delay={200}>
                        <input name="email" placeholder="Email*" type="email" />
                      </div>
                      <div className="col-lg-12" data-aos="fade-up" data-aos-delay={200}>
                        <textarea name="message" placeholder="Your Message*" defaultValue={""} />
                        <button className="btn btn-primary mt-4" data-aos="fade-up" data-aos-delay={200} type="submit">Submit <i className="fa fa-arrow-right" /></button>
                      </div>
                    </div>
                  </form>
                  {/* <p className="form-messege" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Contact;
