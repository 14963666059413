import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content'
import storage from "./firebaseConfig.js"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";

function Orders(){
    const MySwal = withReactContent(Swal)
    const [order_info, setorders] = useState([]);
    // const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/fetchOrders";
    const baseUrl = "http://localhost:9200/api/fetchOrders";
    const token = localStorage.getItem('justgymfitnesstokenize')

    const logout = (e) => {
        window.location.href = "/"
        localStorage.removeItem('justgymfitnesstokenize');
        localStorage.removeItem('justgymfitnessfname')
      }

      var body = {
        "accesstoken": token
      };

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };

      const getordersinfo = async (e) => {
        axios.post(baseUrl, body, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          if(!response){
            setorders(0);
          }else{
            const sortedArray = response.data.data;
            const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
            setorders(numDescending);
            setorders(numDescending);
          }
        });
      };

      // const getitemsinfo = async (e) => {
      //   axios.post(itemsUrl, body, axiosConfig)
      //   .then((response) => {
      //     console.log("response received: ", response);
      //     if(!response){
      //       setcust(0);
      //     }else{
      //       const sortedArray = response.data.data;
      //       const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
      //       setitem(numDescending);
      //       setitem(numDescending);
      //       console.log(item_info)
      //     }
      //   });
      // };

      useEffect(() => {
        getordersinfo();
        // getitemsinfo();
      }, []);


      const columns = [
        {
          name: 'CUSTOMER NAME',
          selector: (row) => row.customerFirstName+' '+row.customerLastName,
          sortable: true,
          width: "10rem",
            cellExport: (row) => row.customerFirstName+' '+row.customerLastName
      },
      {
        name: 'TOTAL AMOUNT',
        selector: (row) => `£${(row.OrdertotalAmount / 1).toFixed(2)}`,
        sortable: true,
        width: '8rem',
        cellExport: (row) => `£${(row.OrdertotalAmount / 1).toFixed(2)}`,
      },
      {
        name: 'TOTAL QUANTITY',
        selector: (row) => row.OrdertotalQuantity,
        sortable: true,
        width: '10rem',
        cellExport: row => row.OrdertotalQuantity 
      },
      {
        name: 'ADDRESS',
        selector: (row) => row.customerAddress,
        sortable: true,
        width: '15rem',
        cellExport: row => row.customerAddress 
      },
      {
        name: 'POSTCODE',
        selector: (row) => row.customerpostcode,
        sortable: true,
        width: '15rem',
        cellExport: row => row.customerpostcode 
      },
      
      {
        name: 'POSTCODE',
        selector: (row) => row.customerPhone,
        sortable: true,
        width: '15rem',
        cellExport: row => row.customerPhone 
      },
    {
      name: 'ACTION',
      cell: (row) => (
        <button className='btn btn-sm btn-danger'> EXPAND</button>
      ),
      sortable: true,
      width: '8rem',
    }
    ];
      const data=order_info;
      const tableData = {
        columns,
        data
      };

      const update = async (e) => {
        const itemID = e.target.id;
        const body2 = {
          itemId: itemID
        };
      
        try {
         
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };



     
    

      return (
              <div>
     <Header/>
     <div className="account-dashboard pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3">
              {/* Nav tabs */}
              <div className="dashboard_tab_button" data-aos="fade-up" data-aos-delay={0}>
                <ul role="tablist" className="nav flex-column dashboard-list">
                  <li><a href="/admindash"  className="nav-link ">Dashboard</a></li>
                  <li><a href="/items"  className="nav-link">Items</a></li>
                  <li> <a href="/orders"  className="active nav-link">Orders</a></li>
                  <li><a href="/customers" className="nav-link">Customers</a></li>
                  <li><a href="#account" data-bs-toggle="tab" className="nav-link">Account details</a>
                  </li>
                  <li><a href="#" onClick={logout}className="nav-link">logout</a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-9 col-lg-9">
              {/* Tab panes */}
              <div className="tab-content dashboard_content" data-aos="fade-up" data-aos-delay={200}>
                <div className="tab-pane fade " id="dashboard">
                  <h4>Dashboard </h4>
                  <div className="row">
                    <div className="col-lg-3">
                        <div className="card cardies">
                        Total Orders: 0
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card cardies2">
                        Total items: 3
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card cardies3">
                        All customers: 0
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card cardies4">
                        Payments: 0
                        </div>
                    </div>
                    </div>

                  {/* <p>From your account dashboard. you can easily check &amp; view your <a href="#">recent
                      orders</a>, manage your <a href="#">shipping and billing addresses</a> and <a href="#">Edit your password and account details.</a></p> */}
                </div>
                <div className="tab-pane fade show active" id="orders">
                  <h4>Orders</h4>
                  <div className="table_page table-responsive">
                    <table>
                    {order_info == null ? <Spinner animation="border" /> : ""}
                    <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                    </table>
                  </div>
                </div>
                <div className="tab-pane fade " id="items">
                  <h4>Items</h4>
                  <button className="btn btn-primary btn-sm" style={{float: 'right'}} type="submit" data-bs-toggle="modal" data-bs-target="#admindashmodal">New Item</button>
                  {/* <button className="btn btn-primary btn-sm" style={{float: 'right'}} onClick={getitemsinfo}>New Item</button> */}
                  <div className="table_page table-responsive">
                  </div>
                </div>
                <div className="tab-pane" id="customers">
                <div className="table_page table-responsive">
               
                  </div>
                </div>
                <div className="tab-pane fade" id="account">
                  <h3>Account details </h3>
                  <div className="login">
                    <div className="login_form_container">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default Orders;
