import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import ClipLoader from "react-spinners/ClipLoader";
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from 'axios';
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


function Home(){
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("orange");
    const [state , setState] = useState({
        email: '',
        password: '',
    })

    const viewItem = (id) => {
      // e.preventDefault()
      if (!id) {
        console.log('ID not found');
      } else {
        const myidd = id;
        window.location.href = `singleproduct?myidd=${myidd}`;
      }
    }; 

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }


      const [selectedCategory, setSelectedCategory] = useState('All');
      // const itemsUrl = "http://localhost:9200/api/fetchItems";
      const itemsUrl = "https://justgymbackend.starlightsoftware.co.bw/api/fetchItems";
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
      const [item_info, setitem] = useState([]);
      const [men_info, setMencategory] = useState([]);
      const [women_info, setWomenCategory] = useState([]);
      const [accessory_info, setAccessoriesCategory] = useState([]);

      const handleCategoryClick = (category) => {
        console.log(`Category clicked: ${category}`);
        setSelectedCategory(category);
        console.log(selectedCategory);
      }
    
      const getitemsinfo = async () => {
        axios.post(itemsUrl, axiosConfig)
          .then((response) => {
            console.log("Response received:", response);
            if (!response) {
              setitem([]);
             setLoading(!loading)
            } else {
              setLoading(!loading)
              const sortedArray = response.data.data;
              const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
      
              if (selectedCategory == "All") {
                setitem(numDescending.slice(0, 6));
                console.log('ll')
              } else if (selectedCategory === "Men") {
                console.log('llo')
                const menCategoryItems = numDescending.filter(item => item.categoryId == 1).slice(0, 6);
                setitem(menCategoryItems);
              } else {
                // Handle other categories or set an empty array if needed
                setitem([]);
              }
            }
          });
      };
      

      const viewItems = (id) => {
        if (!id) {
          console.log('ID not found');
        } else {
          const myidd = id;
          window.location.href = `shop?myidd=${myidd}`;
        }
      }; 

      
      useEffect(() => {
        getitemsinfo();
        
      }, []);


      return (
              <div>
     <Header/>
     {/* <div className="section ">
        <div className="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1" style={{height: '650px', borderRadius: '2%'}}>
          <div className="swiper-wrapper">
            <div className="hero-slide-item-2 slider-height swiper-slide d-flex bg-color1" style={{marginTop: '2px'}}>
              <div className="container align-self-center">
                <div className="row">
                  <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5 align-self-center sm-center-view">
                    <div className="hero-slide-content hero-slide-content-2 slider-animated-1">
                      <h2 className="title-1">Get Stylish and Fit!</h2>
                      <a href="/shop" className="btn btn-lg btn-primary btn-hover-dark"> Shop
                        Now <i className="fa fa-shopping-basket ml-15px" aria-hidden="true" /></a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 d-flex justify-content-center position-relative">
                    <div className>
                      <div className="rounded-circle">
                        <div className="hero-slide-image">
                          <img src="assets/images/wewe.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


<div className="section ">
        <div className="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1">
          <div className="swiper-wrapper">
           
            <div className="hero-slide-item slider-height swiper-slide d-flex bg-color2">
              <div className="container align-self-center">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 align-self-center sm-center-view">
                    <div className="hero-slide-content slider-animated-1">
                      <span className="category">New Collection 2024</span>
                      {/* <h2 className="title-1">Save Up To </h2> */}
                      <h2 className="title-2">Get Stylish and Fit!</h2>
                      <a href="/shop" className="btn btn-lg btn-primary btn-hover-dark"> Shop Now <i className="fa fa-shopping-basket ml-15px" aria-hidden="true" /></a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center position-relative" style={{marginLeft: '85px'}}>
                  <div className="rounded-circle" >
                        <div className="hero-slide-image" style={{width: '200%'}}>
                          <img src="assets/images/justg.jpeg" alt=""  />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination swiper-pagination-white" />
          {/* <div className="swiper-buttons">
            <div className="swiper-button-next" />
            <div className="swiper-button-prev" />
          </div> */}
        </div>
      </div>

      <div className="product-area pt-100px pb-100px">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center mb-0">
                  {/* <h2 className="title">Product Categories</h2> */}
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="tab-content mb-30px0px">
                  <div className="tab-pane fade show active" id="tab-product--all">
                    <div className="row">
                      <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay={200}>
                        <div className="product">
                          <center><h6><b>SHOP WOMEN</b></h6></center>
                          <div className="thumb">
                            <a href="#" onClick={() => viewItems('2')}  className="image">
                              <img src="assets/images/homeicon2.png" alt="Product" />
                              <img className="hover-image" src="assets/images/homeicon2.png" alt="Product" />
                            </a>
                            <span className="badges">
                              <span className="new">New</span>
                            </span>
                            {/* <button title="Add To Cart" className=" add-to-cart">Shop</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay={400}>
                        <div className="product">
                          <center><h6><b>SHOP MEN</b></h6></center>
                          <div className="thumb">
                            <a href="#" onClick={() => viewItems('1')}  className="image">
                              <img src="assets/images/homeicon1.png" alt="Product" />
                              <img className="hover-image" src="assets/images/homeicon1.png" alt="Product" />
                            </a>
                            <span className="badges">
                              {/* <span className="sale">-10%</span> */}
                              <span className="new">New</span>
                            </span>
                            {/* <button title="Add To Cart" className="add-to-cart">SHOP</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay={600}>
                        <div className="product">
                          <center><h6><b>SHOP ACCESSORIES</b></h6></center>
                          <div className="thumb">
                            <a href="#" onClick={() => viewItems('3')}  className="image">
                              <img src="assets/images/accessory.jpeg" alt="Product" />
                              <img className="hover-image" src="assets/images/accessory.jpeg" alt="Product" />
                            </a>
                            <span className="badges">
                              {/* <span className="sale">-7%</span> */}
                            </span>
                            {/* <button title="Add To Cart" className=" add-to-cart">SHOP</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay={600}>
                        <div className="product">
                          <center><h6><b>CLEARANCE SALE</b></h6></center>
                          <div className="thumb">
                            <a href="#" onClick={() => viewItems('4')}  className="image">
                              <img src="assets/images/sale.jpeg" alt="Product" height={'300'}/>
                              <img className="hover-image" src="assets/images/sale.jpeg" alt="Product" />
                            </a>
                            <span className="badges">
                              <span className="sale">-20%</span>
                            </span>
                            {/* <button title="Add To Cart" className=" add-to-cart">SHOP</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/shop" className="btn btn-md btn-primary btn-hover-dark m-auto">VIEW ALL <i className="fa fa-arrow-right ml-15px" aria-hidden="true" />
                </a>
              </div>
            </div>
          </div>
        </div>


        <div className="product-area pb-20px">
          <div className="container">
            <div className="row">
              <div className="col-lg col-md col-12">
                <div className="section-title mb-0">
                  <h2 className="title text-small">New Arrivals</h2>
                </div>
              </div>
              <div className="col-lg-auto col-md-auto col-12">
               
              </div>
            </div>
            <div className="row">
              <div className="col">
              <div className="tab-pane fade show active" id="tab-product-all">
                <div className="row">
                <ClipLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={70}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                {item_info.map((item, index) => (
                          <div className="col-md-4 col-sm-6 col-xs-6 mb-30px" data-aos="fade-up" data-aos-delay={200} key={index}>
                            <div className="product">
                              <div className="thumb">
                              <a href="#" onClick={() => viewItem(item.id)} className="image">
                                <img src={item.picture1} alt="Product" />
                                <img className="hover-image" src={item.picture1} alt="Product" />
                              </a>
                                <span className="badges">
                                   <span className="new">New</span>
                                </span>
                              </div>
                              <div className="content">
                                <h5 className="title">
                                  {item.itemName}
                                </h5>
                                <span className="price">
                                  <span className="new">£{item.price}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                </div>
                <br/>
                    <br/>
                    <br/>
            </div>
            <div className="tab-pane fade show" id="tab-product-new">
            <div className="row">
            {men_info.length > 0 ? (
                men_info.map((men, index) => (
                // {men_info.map((men, index) => (
                          <div className="col-md-4 col-sm-6 col-xs-6 mb-30px" data-aos="fade-up" data-aos-delay={200} key={index}>
                            <div className="product">
                              <div className="thumb">
                              <a href="#" onClick={() => viewItem(men.id)} className="image">
                                <img src={men.picture1} alt="Product" />
                                <img className="hover-image" src={men.picture1} alt="Product" />
                              </a>
                                <span className="badges">
                                   <span className="new">New</span>
                                </span>
                              </div>
                              <div className="content">
                                <h5 className="title">
                                </h5>
                                <span className="price">
                                  <span className="new">£{men.price}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          ))
                          ) : (
                            <p></p>
                          )}
                </div>
            </div>
              </div>
            </div>
          </div>
        </div>


      





        <div className="deal-area deal-bg deal-bg-2" style={{backgroundColor: 'black'}}>
          <div className="container ">
            <div className="row">
              <div className="col-12">
                <div className="deal-inner position-relative pt-100px pb-100px">
                  <div className="deal-wrapper">
                    <div className="deal-timing">
                      <h5 style={{color: 'white', float: 'left'}}>Subscribe to stay updated</h5>
                      <br/>
                      <br />
                      <input type="text" placeholder="Email" value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" className="form-control" />
                      <br/>
                      <a href="/shop" className="btn btn-lg btn-primary btn-sm btn-hover-dark m-auto" style={{float: 'left'}}> Submit</a>
                    </div>
                  </div>
                  <div className="deal-image">
                    <img className="img-fluid" src="assets/images/homeicon10.jpeg" width="30%" alt="" style={{marginBottom: '50px', float: 'right'}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default Home;
