// Header.js
import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import { Link, useLocation } from 'react-router-dom';


function Footer() {
const [searchTerm, setSearchTerm] = useState('');
const MySwal = withReactContent(Swal)
const location = useLocation();
const [state , setState] = useState({
  loginemail: '',
  loginpassword: ''
})
// const loginUrl = "http://localhost:9200/api/login";
const loginUrl = "https://justgymbackend.starlightsoftware.co.bw/api/login";
const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const viewItem = (id) => {
  if (!id) {
    console.log('ID not found');
  } else {
    const myidd = id;
    window.location.href = `shop?myidd=${myidd}`;
  }
}; 

const handleSearch = (e) => {
  e.preventDefault()
  // Redirect to the search results page with the filtered search term
  // history.push(`/search-results?term=${encodeURIComponent(searchTerm)}`);
   window.location.href = `shop?term=${encodeURIComponent(searchTerm)}`;
};




var postloginData = {
  "email": state.loginemail,
  "password": state.loginpassword,
};

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const isCurrentPage = (path) => {
    // Check if the current route matches the provided path
    return location.pathname === path;
  };

  const handleLoginSubmit = (e) => {
    if (!state.loginemail || !state.loginemail){
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      })
    }else{
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          axios.post(loginUrl, postloginData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
            localStorage.setItem("justgymfitnesstokenize", response.data.data.accessToken);
            localStorage.setItem("justgymfitnessfname", response.data.data.firstname);
            localStorage.setItem("justgymfitnessfi", response.data.data.id);
            localStorage.setItem("justgymfitnessrole", response.data.data.role);
            if(response.data.data.role == 'customer'){
            window.location.href="/";
            }else if(response.data.data.role == 'admin'){
                window.location.href="admindash";
            }
            
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message)
          })
        },
      }).then(() => {
      })
    }
  }




  return (
    <div>
    <div>
        <div className="footer-area m-lrb-30px">
          <div className="footer-container">
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-3 mb-md-30px mb-lm-30px">
                    <div className="single-wedge">
                      <div className="footer-logo">
                        <a href="index"><img src="assets/images/Frame4142.png" width={150} alt="" /></a>
                      </div>
                      <ul className="link-follow">
                        <li>
                          <a className="m-0" title="Twitter" href="https://twitter.com/justgymfitness"><i className="fa fa-twitter" aria-hidden="true" /></a>
                        </li>
                        <li>
                          <a title="Facebook" href="https://facebook.com/justgymfitness"><i className="fa fa-facebook" aria-hidden="true" />
                          </a>
                        </li>
                        <li>
                          <a title="Instagram" href="htpps://instagram.com/justgymfitness"><i className="fa fa-instagram" aria-hidden="true" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-lg-2 mb-md-30px mb-lm-30px pl-lg-50px">
                    <div className="single-wedge">
                      <h4 className="footer-herading">Shop</h4>
                      <div className="footer-links">
                        <div className="footer-row">
                          <ul className="align-items-center">
                            {/* <li className="li"><a className="single-link" href="#">Support
                              </a></li> */}
                            <li onClick={() => viewItem('2')} className="li"><a className="single-link" href="#">Women</a></li>
                            <li onClick={() => viewItem('1')} className="li"><a className="single-link" href="#">Men</a></li>
                            <li onClick={() => viewItem('3')} className="li"><a className="single-link" href="#">Accessories</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 mb-lm-30px pl-lg-50px">
                    <div className="single-wedge">
                      <h4 className="footer-herading">Information</h4>
                      <div className="footer-links">
                        <div className="footer-row">
                          <ul className="align-items-center">
                            <li className="li"><a className="single-link" href="about"> About </a>
                            </li>
                            <li className="li"><a className="single-link" href="/contact">Contact Us</a></li>
                            <li className="li"><a className="single-link" href="#">My Account</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 mb-lm-30px pl-lg-50px">
                    <div className="single-wedge">
                      <h4 className="footer-herading">Company</h4>
                      <div className="footer-links">
                        <div className="footer-row">
                          <ul className="align-items-center">
                            <li className="li"><a className="single-link" href="/faq">FAQ</a>
                            </li>
                            <li className="li"><a className="single-link" href="/privacy">Privacy Policy</a>
                            </li>
                            {/* <li className="li"><a className="single-link" href="login">Shipping</a></li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <p className="copy-text"> © 2024 <strong>JUSTGYM</strong> 
                    </p></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal popup-search-style" id="searchActive">
          <button type="button" className="close-btn" data-bs-dismiss="modal"><span aria-hidden="true">×</span></button>
          <div className="modal-overlay">
            <div className="modal-dialog p-0" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <h2>Search Your Product</h2>
                  <form className="navbar-form position-relative" role="search">
                    <div className="form-group">
                      <input type="text" className="form-control" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search here..." />
                    </div>
                    <button type="submit" onClick={handleSearch} className="submit-btn"><i className="pe-7s-search" /></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal popup-login-style" id="loginActive">
          <button type="button" className="close-btn" data-bs-dismiss="modal"><span aria-hidden="true">×</span></button>
          <div className="modal-overlay">
            <div className="modal-dialog p-0" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="login-content">
                    <h2 style={{color: 'black'}}>Log in</h2>
                    <h3  style={{color: 'black'}}>Log in to your account</h3>
                    <form action="#">
                      <input type="text" value={state.loginemail} onChange={handleChange} id="loginemail" name="loginemail" placeholder="email" />
                      <input type="password" value={state.loginpassword} onChange={handleChange} id="loginpassword" name="loginpassword" placeholder="Password" />
                      <div className="remember-forget-wrap">
                        {/* <div className="remember-wrap">
                          <input type="checkbox" />
                          <p>Remember</p>
                          <span className="checkmark" />
                        </div> */}
                        {/* <div className="forget-wrap">
                          <a href="#">Forgot your password?</a>
                        </div> */}
                      </div>
                      <button type="button" onClick={handleLoginSubmit}>Log in</button>
                      <div className="member-register">
                        <p> Not a member? <a href="/register"  style={{color: 'black'}}> Register now</a></p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal modal-2 fade" id="exampleModal" tabIndex={-1} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
                    {/* Swiper */}
                    <div className="swiper-container zoom-top">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/zoom-image/1.jpg" alt="" />
                        </div>
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/zoom-image/2.jpg" alt="" />
                        </div>
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/zoom-image/3.jpg" alt="" />
                        </div>
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/zoom-image/4.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-container zoom-thumbs mt-3 mb-3">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/small-image/1.jpg" alt="" />
                        </div>
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/small-image/2.jpg" alt="" />
                        </div>
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/small-image/3.jpg" alt="" />
                        </div>
                        <div className="swiper-slide">
                          <img className="img-responsive m-auto" src="assets/images/product-image/small-image/4.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay={200}>
                    <div className="product-details-content quickview-content">
                      <h2>Ardene Microfiber Tights</h2>
                      <div className="pricing-meta">
                        <ul>
                          <li className="old-price not-cut">$18.90</li>
                        </ul>
                      </div>
                      <div className="pro-details-rating-wrap">
                        <div className="rating-product">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <span className="read-review"><a className="reviews" href="#">( 5 Customer Review
                            )</a></span>
                      </div>
                      <p className="mt-30px mb-0">Lorem ipsum dolor sit amet, consect adipisicing elit, sed do
                        eiusmod tempor incidi ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita ullamco
                        laboris nisi
                        ut aliquip ex ea commodo </p>
                      <div className="pro-details-quality">
                        <div className="cart-plus-minus">
                          <input className="cart-plus-minus-box" type="text" name="qtybutton" defaultValue={1} />
                        </div>
                        <div className="pro-details-cart">
                          <button className="add-cart" href="#"> Add To
                            Cart</button>
                        </div>
                        <div className="pro-details-compare-wishlist pro-details-wishlist ">
                          <a href="wishlist"><i className="pe-7s-like" /></a>
                        </div>
                        <div className="pro-details-compare-wishlist pro-details-compare">
                          <a href="compare"><i className="pe-7s-refresh-2" /></a>
                        </div>
                      </div>
                      <div className="pro-details-sku-info pro-details-same-style  d-flex">
                        <span>SKU: </span>
                        <ul className="d-flex">
                          <li>
                            <a href="#">Ch-256xl</a>
                          </li>
                        </ul>
                      </div>
                      <div className="pro-details-categories-info pro-details-same-style d-flex">
                        <span>Categories: </span>
                        <ul className="d-flex">
                          <li>
                            <a href="#">Fashion.</a>
                          </li>
                          <li>
                            <a href="#">eCommerce</a>
                          </li>
                        </ul>
                      </div>
                      <div className="pro-details-social-info pro-details-same-style d-flex">
                        <span>Share: </span>
                        <ul className="d-flex">
                          <li>
                            <a href="https://facebook.com/justgymfitness"><i className="fa fa-facebook" /></a>
                          </li>
                          <li>
                            <a href="https://twitter.com/justgymfitness"><i className="fa fa-twitter" /></a>
                          </li>
                          {/* <li>
                            <a href="https://"><i className="fa fa-google" /></a>
                          </li> */}
                          <li>
                            <a href="#"><i className="fa fa-youtube" /></a>
                          </li>
                          <li>
                            <a href="#"><i className="fa fa-instagram" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  );
}

export default Footer;
