import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Faq(){
      return (
     <div>
     <Header/>
    
     <div className="login-register-area pb-100px pt-100px faq-area">
        <div className="container">
          <div className="row">
            <div className="ml-auto mr-auto col-lg-9">
              <div className="checkout-wrapper">
                <div className="inner-descripe" data-aos="fade-up" data-aos-delay={200}>
                  <h4 className="title">Below are frequently asked questions, you may find the answer you require
                  </h4>
                  
                </div>
                <div id="faq" className="panel-group">
                  <div className="panel panel-default single-my-account" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>1 .</span> <a data-bs-toggle="collapse" href="#my-account-1" className="collapsed" aria-expanded="true">
                      What is Justgym Fitness Wear?</a></h3>
                    </div>
                    <div id="my-account-1" className="panel-collapse collapse show" data-bs-parent="#faq">
                      <div className="panel-body">
                      Justgym Fitness Wear is a premier activewear brand that combines style, comfort, and performance. We offer a wide range of activewear designed to enhance your workout experience while keeping you fashionable and confident.
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default single-my-account" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>2 .</span> <a data-bs-toggle="collapse" href="#my-account-2" aria-expanded="false" className="collapsed">What makes Justgym Fitness Wear unique?</a></h3>
                    </div>
                    <div id="my-account-2" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">Our uniqueness lies in our commitment to quality, innovative
                            designs, and a community-driven approach. We prioritize high-
                            quality materials, tailor our designs for a variety of body types, and
                            foster a sense of community among fitness enthusiasts.
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default single-my-account" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>3 .</span> <a data-bs-toggle="collapse" href="#my-account-3">What type of activewear does Justgym offer?</a></h3>
                    </div>
                    <div id="my-account-3" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">We offer a diverse collection of activewear, including workout tops, leggings, shorts, sports bras, jackets, and more. Our range is designed for various activities such as gym workouts, yoga, running, and daily activities.
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default single-my-account" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>4 .</span> <a data-bs-toggle="collapse" href="#my-account-4">Are Justgym products suitable for all body types?
                          </a></h3>
                    </div>
                    <div id="my-account-4" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">Absolutely. We believe in inclusivity and offer activewear in a range of sizes to cater to different body types. Our designs are crafted to flatter and provide comfort to individuals of various shapes and sizes.
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>5 .</span> <a data-bs-toggle="collapse" href="#my-account-5">How does the sizing work?</a>
                      </h3>
                    </div>
                    <br/>
                    <div id="my-account-5" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">Our sizing charts are available on the product pages to help you find the perfect fit. If you&#39;re unsure about your size, our customer support team is always here to assist you.
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>6 .</span> <a data-bs-toggle="collapse" href="#my-account-6">What materials are used in Justgym activewear?</a>
                      </h3>
                    </div>
                    
                    <div id="my-account-6" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">We use high-performance fabrics that offer breathability, moisture-wicking, and flexibility. Our materials are selected to ensure maximum comfort during your workouts.
                      </div>
                    </div>
                  </div>
                    <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>7 .</span> <a data-bs-toggle="collapse" href="#my-account-7">Can I wear Justgym activewear for activities beyond the gym?</a>
                      </h3>
                    </div>
                    <div id="my-account-7" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">Absolutely. Our activewear is designed with versatility in mind. Youcan confidently wear our pieces for your workout sessions and seamlessly transition to your everyday activities while still looking stylish.
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>8 .</span> <a data-bs-toggle="collapse" href="#my-account-8">Is JustGym committed to sustainability?</a>
                      </h3>
                    </div>
                    <div id="my-account-8" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">Yes, we are. We prioritize sustainability by using eco-friendly materials and adopting environmentally conscious practices in our production processes.
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>9 .</span> <a data-bs-toggle="collapse" href="#my-account-9">How can I join the Justgym community?</a>
                      </h3>
                    </div>
                    <div id="my-account-9" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">We welcome you to join our community by following us on our social media platforms and engaging with our content. Share your fitness journey, achievements, and challenges using our products to become a part of the Justgym community.
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>10 .</span> <a data-bs-toggle="collapse" href="#my-account-10">How does the sizing work?</a>
                      </h3>
                    </div>
                    <div id="my-account-10" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">Yes, we offer a hassle-free return and exchange policy. Please refer to our Returns and Exchanges page for detailed information on the process.
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>11 .</span> <a data-bs-toggle="collapse" href="#my-account-11">How can I contact Justgym&#39;s customer support?</a>
                      </h3>
                    </div>
                    <div id="my-account-11" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">You can reach out to our customer support team through the contact any queries or concerns you might have.
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>12 .</span> <a data-bs-toggle="collapse" href="#my-account-12">How does the sizing work?</a>
                      </h3>
                    </div>
                    <div id="my-account-12" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">Yes, we offer international shipping to many countries. Please refer to our Shipping page for more information on available destinations and shipping times.
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>13 .</span> <a data-bs-toggle="collapse" href="#my-account-13">Are there any ongoing promotions or discounts?</a>
                      </h3>
                    </div>
                    <div id="my-account-13" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">We often run promotions and offer discounts to our customers. Keep an eye on our website and social media channels for updates on current offers.
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="panel panel-default single-my-account m-0" data-aos="fade-up" data-aos-delay={200}>
                    <div className="panel-heading my-account-title">
                      <h3 className="panel-title"><span>14 .</span> <a data-bs-toggle="collapse" href="#my-account-14">How can I stay updated with Justgym news and releases?</a>
                      </h3>
                    </div>
                    <div id="my-account-14" className="panel-collapse collapse" data-bs-parent="#faq">
                      <div className="panel-body">To stay informed about our latest news, product releases, and
                        promotions, subscribe to our newsletter and follow us on our social
                        media platforms.
                        If you have any more questions or need further assistance, feel free
                        to reach out to us. Our goal is to provide you with the best
                        activewear experience possible.
                      </div>
                    </div>
                  </div>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Faq;
