import React, {useEffect} from 'react';

function Header() {

  const username = localStorage.getItem('justgymfitnessfname')
  const cartItem = localStorage.getItem('mygymfitnesscart');



    return (
    <div>
    <div className="header-to-bar"> FREE STANDARD SHIPPING ON ORDERS ABOVE £70 </div>
    <header>
      <div className="header-main sticky-nav ">
        <div className="container position-relative">
          <div className="row">
            <div className="col-auto align-self-center">
              <div className="header-logo headerz">
                <a href="/"><img className="headerz" src="assets/images/Frame414.png" alt="Site Logo" width={100}/></a>
              </div>
            </div>
            <div className="col align-self-right d-none d-lg-block">
              <div className="main-menu">
              <ul>
              <li className={window.location.pathname === '/' ? 'active' : ''}><a href="/">Home</a></li>
              <li className={window.location.pathname === '/shop' ? 'active' : ''}><a href="/shop">Shop</a></li>
              <li className={window.location.pathname === '/about' ? 'active' : ''}><a href="/about">About us</a></li>
              <li className={window.location.pathname === '/contact' ? 'active' : ''}><a href="/contact">Contact us</a></li>
            </ul>
              </div>
            </div>
            <div className="col col-lg-auto align-self-center pl-0">
              <div className="header-actions">
              {username? <a href="/admindash" className='logintext'>{username}</a>:
                <a href="login" className='logintext' data-bs-toggle="modal" data-bs-target="#loginActive">SIGN IN</a>
              }
               
                <a href="#" className="loginicon header-action-btn" data-bs-toggle="modal" data-bs-target="#loginActive">
                  <i className="pe-7s-user" />
                </a>
                <a href="#" className="header-action-btn" data-bs-toggle="modal" data-bs-target="#searchActive">
                  <i className="pe-7s-search txt"/>
                </a>
                <a href="/cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                  <i className="pe-7s-shopbag" />
                  <span className="header-action-num">{cartItem}</span>
                </a>
                {/* <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                  <i className="pe-7s-menu" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div></header>

    <div className="offcanvas-overlay"></div>
            
            <div id="offcanvas-cart" className="offcanvas offcanvas-cart">
              <div className="inner">
                <div className="head">
                  <span className="title">Cart</span>
                  <button className="offcanvas-close">×</button>
                </div>
                <div className="body customScroll">
                  <ul className="minicart-product-list">
                    <li>
                      <a href="single-product" className="image"><img src="assets/images/product-image/1.jpg" alt="Cart product Image" /></a>
                      <div className="content">
                        <a href="single-product" className="title">Women's Elizabeth Coat</a>
                        <span className="quantity-price">1 x <span className="amount">$18.86</span></span>
                        <a href="#" className="remove">×</a>
                      </div>
                    </li>
                    <li>
                      <a href="single-product" className="image"><img src="assets/images/product-image/2.jpg" alt="Cart product Image" /></a>
                      <div className="content">
                        <a href="single-product" className="title">Long sleeve knee length</a>
                        <span className="quantity-price">1 x <span className="amount">$43.28</span></span>
                        <a href="#" className="remove">×</a>
                      </div>
                    </li>
                    <li>
                      <a href="single-product" className="image"><img src="assets/images/product-image/3.jpg" alt="Cart product Image" /></a>
                      <div className="content">
                        <a href="single-product" className="title">Cool Man Wearing Leather</a>
                        <span className="quantity-price">1 x <span className="amount">$37.34</span></span>
                        <a href="#" className="remove">×</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="foot">
                  <div className="buttons mt-30px">
                    <a href="cart" className="btn btn-dark btn-hover-primary mb-30px">view cart</a>
                    <a href="checkout" className="btn btn-outline-dark current-btn">checkout</a>
                  </div>
                </div>
              </div>
            </div>


    <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
      <button className="offcanvas-close" />
      <div className="inner customScroll">
        <div className="offcanvas-menu mb-4">
          <ul>
            <li><a href="#"><span className="menu-text">Home</span></a>
            </li>
            <li><a href="#"><span className="menu-text">Shop</span></a>
            </li>
            <li><a href="about">About Us</a></li>
            <li><a href="contact">Contact Us</a></li>
          </ul>
        </div>
      </div>
    </div>

    

  </div>
    )
}
export default Header;
