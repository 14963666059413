import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import { useLocation } from 'react-router-dom';
const override= {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


function Shop(){
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("orange");
  // const itemsUrl = "http://localhost:9200/api/fetchItems";
  const itemsUrl = "https://justgymbackend.starlightsoftware.co.bw/api/fetchItems";
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };
  const [item_info, setitem] = useState([]);
  const [count, setCount] = useState(0);
  const [menCat, setMencat] = useState([]);
  const [womenCat, setWomencat] = useState([]);
  const [assCat, setAsscat] = useState([]);
  const [cart, setcart] = useState([]);
  const [itemcartcount, setexistingItem] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultCategory = queryParams.get('myidd'); 
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory || 0); // 0 for "ALL"
  
  const [items, setItems]=useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  var myterm = urlParams.get('term');
  const[searchItem, setSearch]=useState("");
  const[filterItem, setFilter]=useState([]);
  // console.log(myterm)
  

  const getitemsinfo = async (e) => {
    axios.post(itemsUrl, axiosConfig)
    .then((response) => {
      // console.log("response received: ", response);
      if(!response){
      setLoading(!loading)
        setitem(0);
      }else{
      setLoading(!loading)
        const sortedArray = response.data.data;
        const itemCount = response.data.data.length;
        const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
        const filteredMen = sortedArray.filter(item => item.categoryId == 1);
        const filteredwomen = sortedArray.filter(item => item.categoryId == 2);
        const filteredaccessories = sortedArray.filter(item => item.categoryId == 3);
        setitem(numDescending);
        setitem(numDescending);
        setCount(itemCount);
        setMencat(filteredMen);
        setMencat(filteredMen);
        setWomencat(filteredwomen);
        setWomencat(filteredwomen);
        setAsscat(filteredaccessories);
        setAsscat(filteredaccessories);
        // console.log(item_info);
        // console.log(filteredMen);
        // console.log(filteredwomen);
        // console.log(filteredaccessories);
        // console.log(itemCount);
      }
    });
  };

  useEffect(() => {
    getitemsinfo();
    setSelectedCategory(Number(defaultCategory) || 0);
  }, [defaultCategory]);

  let totalcount = 0;
  
  const itemsToDisplay = () => {
    let items = item_info; // Default to all items
    if (selectedCategory === 1) {
      items = item_info.filter((item) => item.categoryId === '1'); // Filter for men's items
      totalcount = items.length;
    } else if (selectedCategory === 2) {
      items = item_info.filter((item) => item.categoryId === '2'); // Filter for women's items
    } else if (selectedCategory === 3) {
      items = item_info.filter((item) => item.categoryId === '3'); // Filter for accessories
    } else if (selectedCategory === 4) {
      items = item_info.filter((item) => item.categoryId === '4'); // Filter for accessories
    }

    if (myterm) {
      items = items.filter((item) =>
        item.itemName.toLowerCase().includes(myterm.toLowerCase())
      );
    }
  
    totalcount = items.length;
    // console.log(totalcount);
  
    return items;
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(Number(event.target.value));
  };
  





  const handleAddToCart = (itemId) => {
    // Retrieve the existing cart from localStorage
    const existingCart = JSON.parse(localStorage.getItem('mygymfitnesscartitem')) || [];
    const existingItemIds = JSON.parse(localStorage.getItem('mygymfitnesscartcartitemid')) || [];
  
    // Clone the existing cart and item IDs to prevent modifying the original data
    const updatedCart = [...existingCart];
    const updatedItemIds = [...existingItemIds];
  
    // Check if the product is already in the cart
    const existingItem = updatedCart.find((item) => item.id === itemId);
  
    if (existingItem) {
      // If the item exists, increase its quantity
      existingItem.quantity += 1;
    } else {
      // If the item is not in the cart, add it with a quantity of 1
      updatedCart.push({ id: itemId, quantity: 1 });
      updatedItemIds.push(itemId);
    }
  
    // Update the cart state
    setcart(updatedCart);
  //  console.log(existingCart);
    // Update local storage with the updated cart and item IDs
    localStorage.setItem('mygymfitnesscartitem', JSON.stringify(updatedCart));
    localStorage.setItem('mygymfitnesscartcartitemid', JSON.stringify(updatedItemIds));
  
    // Calculate the total item count
    const itemCount = updatedCart.reduce((total, item) => total + item.quantity, 0);
  
    // Update local storage with the total item count
    // localStorage.setItem('mygymfitnesscart', itemCount.toString());
    localStorage.setItem('mygymfitnesscart',updatedItemIds.length);
  };

  const handleSearch=(ev)=>{
    const value=document.getElementById('search').value;
    //sort the arra
    setSearch(value)

    const itemer = itemsToDisplay().filter((item) =>
        item.itemName.toLowerCase().includes(value.toLowerCase())
      );
      setFilter(itemer);
  }
  
  
  
  
  

  const viewItem = (id) => {
    if (!id) {
      // console.log('ID not found');
    } else {
      const myidd = id;
      window.location.href = `singleproduct?myidd=${myidd}`;
    }
  }; 

      return (
     <div>
     <Header/>
     <div className="shop-category-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-12">
            {myterm?  <p style={{color: 'red'}}><span></span> Search Term: <b>{myterm}</b></p>: ''}
            <div className="search-container">
  <input type="text" className="search-input" placeholder="Search..." onKeyUp={handleSearch} id="search"/>
  <span className='sort'>Sort by:</span>
  <select className="filter-dropdown" onChange={handleCategoryChange} value={selectedCategory}>
                    <option value={0}>ALL</option>
                    <option value={1}>MEN</option>
                    <option value={2}>WOMEN</option>
                    <option value={3}>ACCESSORIES</option>
                    <option value={4}>SALE</option>
                  </select>
</div>



              {/* <div className="shop-top-bar d-flex">
             {myterm?  <p style={{color: 'red'}}><span></span> Search Term: <b>{myterm}</b></p>: ''}
                <p><span></span> Shop Products</p>
                <div className="select-shoing-wrap d-flex align-items-center">
                  <div className="shot-product">
                    <p>Sort By:</p>
                  </div>
                  <div className="shop-select">
                  <select className="shop-sort" onChange={handleCategoryChange} value={selectedCategory}>
                    <option value={0}>ALL</option>
                    <option value={1}>MEN</option>
                    <option value={2}>WOMEN</option>
                    <option value={3}>ACCESSORIES</option>
                    <option value={4}>SALE</option>
                  </select>
                  </div>
                </div>
              </div> */}
              <div className="shop-bottom-area">
                <div className="row">
                  <div className="col">
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="shop-grid">
                      <div className="row mb-n-30px item-container">
                      <ClipLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={70}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                      {searchItem==""?itemsToDisplay().map((item, index) => (
                          <div className="col-md-4 col-sm-6 col-xs-6 mb-30px" data-aos="fade-up" data-aos-delay={200} key={index}>
                            <div className="product">
                              <div className="thumb">
                              <a href="#" onClick={() => viewItem(item.id)} className="image">
                                <img src={item.picture1} alt="Product" />
                                <img className="hover-image" src={item.picture1} alt="Product" />
                              </a>
                                <span className="badges">
                                   
                                 {item.categoryId==4? <span className="new">SALE</span>: <span className="new">New</span>}
                                </span>
                                {/* <button title="Add To Cart" onClick={() => handleAddToCart(item.id)} className="add-to-cart">Add To Cart</button> */}
                              </div>
                              <div className="content">
                                <h5 className="title">
                                  {item.itemName}
                                </h5>
                                <span className="price">
                                  <span className="new">£{item.price}</span>
                                 {item.categoryId==4? <span className="old">£30</span>: ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        )):
                        filterItem.map((item, index) => (
                          <div className="col-md-4 col-sm-6 col-xs-6 mb-30px" data-aos="fade-up" data-aos-delay={200} key={index}>
                            <div className="product">
                              <div className="thumb">
                              <a href="#" onClick={() => viewItem(item.id)} className="image">
                                <img src={item.picture1} alt="Product" />
                                <img className="hover-image" src={item.picture1} alt="Product" />
                              </a>
                                <span className="badges">
                                   
                                 {item.categoryId==4? <span className="new">SALE</span>: <span className="new">New</span>}
                                </span>
                                {/* <button title="Add To Cart" onClick={() => handleAddToCart(item.id)} className="add-to-cart">Add To Cart</button> */}
                              </div>
                              <div className="content">
                                <h5 className="title">
                                  {item.itemName}
                                </h5>
                                <span className="price">
                                  <span className="new">£{item.price}</span>
                                 {item.categoryId==4? <span className="old">£30</span>: ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      </div>
                    
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Shop;
