import React, {useState,useEffect, CSSProperties} from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



function Singleproduct(){
  const MySwal = withReactContent(Swal)
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("orange");
  const itemsUrl = "https://justgymbackend.starlightsoftware.co.bw/api/fetchItemById";
  const [state , setState] = useState({
    quantity: 1,
    size: '',
    color: ''
  })
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }));
    
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
   // console.log(event.target.value)
  };

  
  const urlParams = new URLSearchParams(window.location.search);
  const [selectedCategory, setSelectedCategory] = useState(''); // 0 for "ALL"

  const [cart, setcart] = useState([]);
  const myidd = urlParams.get('myidd');

  const body = {
    "itemId": myidd
  }
  const [item_info, setitem] = useState([]);

  const getitemsinfo = async (e) => {
    axios.post(itemsUrl, body, axiosConfig)
    .then((response) => {
      setLoading(!loading)
      // console.log("response received: ", response);
      if(!response){
        setitem(0);
      }else{
        setLoading(!loading)
        setitem(response.data.data);
        setitem(response.data.data);
        // console.log(response.data.data)
        // console.log(item_info)
      }
    });
  };
  
  useEffect(() => {
    getitemsinfo();
    
  }, []);

  
  const existingItemCount = localStorage.getItem('mygymfitnesscart') || 0;
  const handleAddToCart = (itemId, selectedCategory) => {
    setLoading(true)
    if(!selectedCategory){
      return MySwal.fire('Please select size')
    }else{
    // Retrieve the existing cart from localStorage
    const existingCart = JSON.parse(localStorage.getItem('mygymfitnesscartitem')) || [];
    const existingItemIds = JSON.parse(localStorage.getItem('mygymfitnesscartcartitemid')) || [];
  
    // Clone the existing cart and item IDs to prevent modifying the original data
    const updatedCart = [...existingCart];
    const updatedItemIds = [...existingItemIds];
  
    // Check if the product is already in the cart
    const checker = updatedItemIds.find((item) => item === itemId);

    const existingItem=updatedCart.find((item) => item.id === itemId);
    if (checker) {
      // If the item exists, increase its quantity
      existingItem.quantity += Number(state.quantity);
    } else {
      // If the item is not in the cart, add it with a quantity of 1
      updatedCart.push({ id: itemId, quantity: Number(state.quantity), size: selectedCategory });
      updatedItemIds.push(itemId);
    }
  
    // Update the cart state
    setcart(updatedCart);
  
    // Update local storage with the updated cart and item IDs
    localStorage.setItem('mygymfitnesscartitem', JSON.stringify(updatedCart));
    localStorage.setItem('mygymfitnesscartcartitemid', JSON.stringify(updatedItemIds));
  
    // Calculate the total item count
    const itemCount = parseInt(updatedCart.reduce((total, item) => total + item.quantity, 0), 10);

// Update local storage with the total item count
    // localStorage.setItem('mygymfitnesscart', itemCount.toString());
    localStorage.setItem('mygymfitnesscart', updatedItemIds.length);

    setTimeout(() => {
      setLoading(false);
      toast('Items added to cart')
    }, 1000);
  };

};
  
  

  const viewItem = (id) => {
    // e.preventDefault()
    if (!id) {
     // console.log('ID not found');
    } else {
      const myidd = id;
      const quantity = state.quantity
      window.location.href = "cart?myidd=" + myidd + "&quantity=" + quantity  ;
    }
  }

      return (
     <div>
      <ToastContainer />
     <Header/>
     <div className="product-details-area pt-100px pb-100px">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
              <ClipLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={70}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                <div className="single-product-sticky mb-30px">
                  <div className="single-product-slider-wrapper d-flex flex-wrap">
                    <div className="single-product-slider-item" data-aos="fade-up" data-aos-delay={200}>
                      <a href="#"><img className="img-responsive mb-30px" src={item_info.picture1} alt="" /></a>
                    </div>
                    <div className="single-product-slider-item swiper-slide" data-aos="fade-up" data-aos-delay={400}>
                      <a href="#"><img className="img-responsive mb-30px" src={item_info.picture2} alt="" /></a>
                    </div>
                    <div className="single-product-slider-item swiper-slide" data-aos="fade-up" data-aos-delay={200}>
                      <a href="#"><img className="img-responsive mb-30px" src={item_info.picture3} alt="" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay={200}>
                <div className="product-details-content quickview-content sticky">
                  <a href='/shop' className='btn btn-primary btn-md'>Back to shop</a>
                   
                  <br/>
                  <h2>{item_info.itemName}</h2>
                  <div className="pricing-meta">
                    <ul>
                      <li className="old-price not-cut">{item_info.price?`${Intl.NumberFormat('en-Us',{style:'currency',currency:'GBP'}).format((item_info.price))}`:''}</li>
                    </ul>
                  </div>
                  
                  <p className="mt-30px mb-0">{item_info.itemDescription} </p>
                  <br/>
                  <p style={{marginBottom: '-30px'}}>Enter Quantity</p>
                  <div className="pro-details-quality">
                    <div className="cart-plus-minus">
                      <input className="cart-plus-minus-box" type="text" value={state.quantity} onChange={handleChange} id="quantity" name="quantity" defaultValue={1} />
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   
                    <div className="pro-details-cart">
                      <select className="shop-sort" onChange={handleChange} value={state.size} id="size" name="size" style={{height: '100%',fontSize:'13px'}}>
                        <option value={''}>Select size</option>
                        <option value={'small'}>Small</option>
                        <option value={'Medium'}>Medium</option>
                        <option value={'Large'}>Large</option>
                        <option value={'XLarge'}>XLarge</option>
                      </select>
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <div className="pro-details-cart">
                      <select className="shop-sort" onChange={handleChange} value={state.color} id="color" name="color" style={{ height: '100%',fontSize:'12px'}}>
                        <option value={''}>Select Color</option>
                        <option value={'small'} style={{ backgroundColor: 'black', color: 'white' }}>Black</option>
                        <option value={'Medium'} style={{ backgroundColor: 'blue', color: 'white' }}>Blue</option>
                        <option value={'Large'} style={{ backgroundColor: 'cream', color: 'black' }}>Cream</option>
                        <option value={'XLarge'} style={{ backgroundColor: 'white', color: 'black' }}>White</option>
                      </select>
                    </div>

                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="pro-details-cart">
                      <button className="add-cart fnt-sm"  onClick={() => handleAddToCart(item_info.id, state.size)}>
                        Add to cart
                      </button>
                    </div>
                    
                    
                    
                  </div>
                  {/* <div className="pro-details-sku-info pro-details-same-style  d-flex">
                    <span>Item ID: </span>
                    <ul className="d-flex">
                      <li>
                        <a href="#">Ch-256xl</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Singleproduct;
