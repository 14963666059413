import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Privacy(){
      return (
     <div>
     <Header/>
    
     <div className="blog-grid pb-100px pt-100px main-blog-page single-blog-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="blog-posts">
                <div className="single-blog-post blog-grid-post">
                  <center><h2 className="blog-title">PRIVACY POLICY</h2></center>
                    <div className="privacy_policy_main_area pb-100px pt-100px">
                        <div className="container">
                        <div className="container-inner">
                            <div className="row">
                            <div className="col-12">
                                <div className="privacy_content section_1" data-aos="fade-up" data-aos-delay={200}>
                                <h2>Who we are</h2>
                                <p>This Privacy Policy describes how Justgym Fitness Wear (&quot;we,&quot; &quot;us,&quot;
                                    or &quot;our&quot;) collects, uses, and discloses personal information when you
                                    visit our website, use our services, or interact with us. By accessing
                                    or using our services, you agree to the practices described in this
                                    Privacy Policy.</p>
                                </div>
                                <div className="privacy_content section_2" data-aos="fade-up" data-aos-delay={400}>
                                <h2>Information We Collect:</h2>
                                <h3>Personal Information:</h3>
                                <p>We may collect information such as your
                                name, email address, shipping address, payment details, and other
                                information you provide when making a purchase or registering an
                                account.</p>
                                <h3>Usage Information:</h3>
                                <p>We collect information about how you interact
                                with our website, including your IP address, browser type, pages
                                viewed, and referring website.</p>
                                <h3>Cookies and Tracking Technologies:</h3>
                                <p>We use cookies and similar
                                    technologies to gather information about your browsing activities on
                                    our website. You can control cookies through your browser settings.</p>
                                <p>If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>
                                <p>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”,
                                    your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
                                <h3>How We Use Your Information:</h3>
                                <p>Provide Services: We use your information to process orders, ship products, and provide customer support.</p>
                                <p>Improve and Personalize: We use data to improve our services, understand customer preferences, and personalize your experience.</p>
                                <p>Marketing: With your consent, we may send you promotional emails about our products and special offers. You can opt-out at any time.</p>
                                </div>
                                <div className="privacy_content section_3" data-aos="fade-up" data-aos-delay={400}>
                                <h2>Sharing Your Information:</h2>
                                <p>We may share your information with third-party service providers who assist us with order processing, payment, shipping, and marketing activities. We do not sell your personal information to third parties.</p>
                                <h3>Your Choices:</h3>
                                <p>You can update your account information, unsubscribe from marketing emails, and manage cookies through your browser settings.</p>
                                <h3>Security:</h3>
                                <p>We implement security measures to protect your information from unauthorized access and use.</p>
                                <h3>Changes to this Policy:</h3>
                                <p>We may update this Privacy Policy from time to time. The latest version will be posted on our website.</p>
                                <p></p>
                                <h3>Contact Us:</h3>
                                <p>If you have questions or concerns about our Privacy Policy, please contact us at [contact information on contact page].</p>
                                <p></p>
                                <h3>Cookies Policy:</h3>
                                <p>This section explains how Justgym Fitness Wear uses cookies and similar technologies on our website.</p>
                                <p></p>
                                <h3>What Are Cookies:</h3>
                                <p>Cookies are small text files that are stored on your device when you visit a website. They help us recognize your device and provide a better user experience.</p>
                                </div>
                                <div className="privacy_content section_3" data-aos="fade-up" data-aos-delay={400}>
                                <h2>How We Use Cookies:</h2>
                                <br/>
                                <h5>We use cookies to:</h5>
                                <ul>
                                    <li> - Remember your preferences and settings.</li>
                                    <li> - Understand how you use our website.</li>
                                    <li> - Provide relevant advertisements.</li>
                                    <li> - Improve our website and services.</li>
                                </ul>
                                <br/>
                                <h3>Types of Cookies:</h3>
                                <p>Essential Cookies: Necessary for the website to function properly.They enable basic features like navigation and access to secure areas.</p>
                                <p>Functional Cookies: Enhance your experience by remembering your preferences and settings.</p>
                                <p>Analytical Cookies: Help us understand how visitors use our website, allowing us to improve it.</p>
                                <p>Advertising Cookies: Used to deliver advertisements that are relevant to your interests.</p>
                                <p>Advertising Cookies: Used to deliver advertisements that are relevant to your interests.</p>
                                <p>Advertising Cookies: Used to deliver advertisements that are relevant to your interests.</p>
                                <p>Managing Cookies: You can manage cookies through your browser settings. Please note that blocking or deleting cookies may impact your browsing experience.</p>
                                <br/>
                                <h3>Terms &amp; Conditions:</h3>
                                <p>This section outlines the terms and conditions governing the use of Justgym Fitness Wear&#39;s website and services.</p>
                                <br/>
                                <h3>Acceptance of Terms:</h3>
                                <p>By accessing our website or using our services, you agree to abide by these Terms &amp; Conditions.</p>
                                <br/>
                                <h3>Intellectual Property:</h3>
                                <p>All content on our website, including text, images, logos, and trademarks, is protected by intellectual property laws and is owned by Justgym Fitness Wear or its licensors.</p>
                                <br/>
                                <h3>User Conduct:</h3>
                                <p>You agree not to use our website for any unlawful or prohibited purposes. You must not engage in activities that disrupt or interfere with our services.</p>
                                    <br/>
                                    <h3>Purchases:</h3>
                                    <p>When making a purchase, you agree to provide accurate and complete information. Prices, availability, and product descriptions are subject to change without notice.</p>
                                    <br/>
                                    <h3>Limitation of Liability:</h3>
                                    <p>We are not liable for any damages arising from your use of our website or services. We do not guarantee the accuracy, completeness, or reliability of any content.</p>
                                    <br/>
                                    <h3>Governing Law:</h3>
                                    <p>These Terms &amp; Conditions are governed by the laws of the United Kingdom. Any disputes will be resolved in the courts of the United Kingdom.</p>
                                </div>
                               
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> 
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Privacy;
