import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content'
import storage from "./firebaseConfig.js"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";

function Items(){
    const MySwal = withReactContent(Swal)
    const [cust_info, setcust] = useState([]);
    const [item_info, setitem] = useState([]);
    const [itemID, setitemID] = useState('');
    

    const [activeTab, setActiveTab] = useState(true);
    const [itemsfetched, setItemsfetched] = useState([]);
    const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/createItem";
    // const deleteURL = "http://localhost:9200/api/deleteItemById";
    const deleteURL = "https://justgymbackend.starlightsoftware.co.bw/api/deleteItemById";
    const fetchbyidURL = "https://justgymbackend.starlightsoftware.co.bw/api/fetchItemById";
    // const fetchbyidURL = "http://localhost:9200/api/fetchItemById";
    const updateUrl = "https://justgymbackend.starlightsoftware.co.bw/api/updateItemById";
    // const updateUrl = "http://localhost:9200/api/updateItemById";
    // const customersUrl = "http://localhost:9200/api/fetchAllCustomersByRole";
    const customersUrl = "https://justgymbackend.starlightsoftware.co.bw/api/fetchAllCustomersByRole";
    // const itemsUrl = "http://localhost:9200/api/fetchItems";
    const itemsUrl = "https://justgymbackend.starlightsoftware.co.bw/api/fetchItems";

    
    const [percent, setPercent] = useState(0);
    const token = localStorage.getItem('justgymfitnesstokenize')

    const [state, setState] = useState({
      itemname: '',
      itemdesc: '',
      price: '',
      category: '',
      numberofitems: '',
      picture1: null,
      picture2: null,
      picture3: null,
      itemID: localStorage.getItem('itemID')?  localStorage.getItem('itemID'): '',
      itemdescupdate: localStorage.getItem('justgymitemdesc')?  localStorage.getItem('justgymitemdesc'): '',
      itemnameupdate: localStorage.getItem('justgymitemname')?  localStorage.getItem('justgymitemname'): '',
      categoryupdate: localStorage.getItem('justgymitemcategory')?  localStorage.getItem('justgymitemcategory'): '',
      numberofitemsupdate: localStorage.getItem('justgymnumberOfItems')?  localStorage.getItem('justgymnumberOfItems'): '',
      priceupdate: localStorage.getItem('justgymitemprice')?  localStorage.getItem('justgymitemprice'): '',
      picture1update: localStorage.getItem('justgympicture1')?  localStorage.getItem('justgympicture1'): '',
      picture2update: localStorage.getItem('justgympicture2')?  localStorage.getItem('justgympicture2'): '',
      picture3update: localStorage.getItem('justgympicture3')?  localStorage.getItem('justgympicture3'): '',
    });


    

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }

      const handleupdateChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

      const logout = (e) => {
        window.location.href = "/"
        localStorage.removeItem('justgymfitnesstokenize');
        localStorage.removeItem('justgymfitnessfname')
      }

      const handleFileChange = (event, inputName) => {
        const file = event.target.files[0];
        if (file) {
          setState({
            ...state,
            [inputName]: file, // Store the file object in state
          });
        }
      };

      var body = {
        "accesstoken": token
    };

    

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };

      const handleImageUpload = async (file) => {
        const storageRef = ref(storage, `/files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
      
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
            },
            (err) => {
              console.log(err);
              reject(err);
            },
            () => {
              // Get the download URL
              getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                  resolve(url);
                })
                .catch((error) => {
                  console.log(error);
                  reject(error);
                });
            }
          );
        });
      };
      

    
      const handlecreateitem = async (e) => {
        e.preventDefault()
        if (!state.itemdesc || !state.itemname|| !state.category|| !state.numberofitems|| !state.price|| !state.picture1|| !state.picture2|| !state.picture3){
          MySwal.fire({
            title: <p>Please fill all required fields</p>,
            didOpen: () => {
            },
          }).then(() => {})
        }else{
          MySwal.showLoading()
          const [picture1URL, picture2URL, picture3URL] = await Promise.all([
            handleImageUpload(state.picture1),
            handleImageUpload(state.picture2),
            handleImageUpload(state.picture3),
          ]);

          var postData = {
            "picture1": picture1URL,
            "picture3": picture2URL,
            "picture2": picture3URL,
            "itemDescription": state.itemdesc,
            "itemName": state.itemname,
            "categoryId": state.category,
            "numberOfItems": state.numberofitems,
            "price": state.price,
            "accesstoken": token
        };

          console.log(postData)
            MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            axios.post(baseUrl, postData, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
              Swal.close()
              Swal.fire({
                  text: response.data.data,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  closeOnCancel: true,
                }).then(result => {
                  console.log(response);
                })
            })
            .catch((err) => {
              Swal.close()
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data)
            })
          },
        }).then(() => {
        })
        }
      }

      const handleUpdateItem = async (e) => {
        e.preventDefault();
      
        if (!state.itemdescupdate || !state.itemnameupdate || !state.categoryupdate || !state.numberofitemsupdate || !state.priceupdate) {
          MySwal.fire({
            title: <p>Please fill all required fields</p>,
            didOpen: () => {},
          }).then(() => {});
        } else {
          MySwal.showLoading()

          // Check if any new images are uploaded
          const picture1URL = state.picture1update ? await handleImageUpload(state.picture1update) : null;
          const picture2URL = state.picture2update ? await handleImageUpload(state.picture2update) : null;
          const picture3URL = state.picture3update ? await handleImageUpload(state.picture3update) : null;
      
          // Prepare updated data
          const updatedData = {
            picture1: picture1URL || state.picture1update, // If no new picture, use the existing URL
            picture2: picture2URL || state.picture2update,
            picture3: picture3URL || state.picture3update,
            itemDescription: state.itemdescupdate,
            itemName: state.itemnameupdate,
            categoryId: state.categoryupdate,
            numberOfItems: state.numberofitemsupdate,
            price: state.priceupdate,
            accesstoken: token,
          };
      
          console.log(updatedData);
      
          MySwal.fire({
            title: <p>Please wait...</p>,
            didOpen: async () => {
              MySwal.showLoading();
              try {
                const response = await axios.post(updateUrl, updatedData, axiosConfig);
      
                Swal.close();
                Swal.fire({
                  text: response.data.data,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  closeOnCancel: true,
                }).then((result) => {
                  console.log(response);
                });
              } catch (err) {
                Swal.close();
                console.log("AXIOS ERROR: ", err);
                return MySwal.fire(err.response.data);
              }
            },
          }).then(() => {});
        }
      };
      

     
      

      

      const getcustomersinfo = async (e) => {
        axios.post(customersUrl, body, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          if(!response){
            setcust(0);
          }else{
            const sortedArray = response.data.data;
            const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
            setcust(numDescending);
            setcust(numDescending);
          }
        });
      };

      const getitemsinfo = async (e) => {
        axios.post(itemsUrl, body, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          if(!response){
            setitem(0);
          }else{
            const sortedArray = response.data.data;
            const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
            setitem(numDescending);
            setitem(numDescending);
            console.log(item_info)
          }
        });
      };

      useEffect(() => {
        getcustomersinfo();
        getitemsinfo();
      }, []);


      const columns = [
        {
          name: 'ITEM NAME',
          selector: (row) => row.itemName,
          sortable: true,
          width: "8rem",
            cellExport: (row) => row.itemName
      },
      {
        name: 'ITEM DESCRIPTION',
        selector: (row) => row.itemDescription,
        sortable: true,
        width: '8rem',
        cellExport: row => row.itemDescription 
  
      },
      {
        name: 'CATEGORY',
        selector: (row) => row.categoryId == 1 ? 
        <span><b>MEN</b></span>
        : row.categoryId == 2
        ? <span><b>WOMEN</b></span>
        : row.categoryId == 3
        ? <span><b>ACCESSORIES</b></span>
        : row.categoryId == 4
        ? <span><b>SALE</b></span>
        : <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2"> </span>,
            sortable: true,
        width: "8rem" ,
       cellExport: row => row.categoryId 
    },
    {
      name: 'ITEM COVERIMAGE',
      cell: (row) => (
        <img src={row.picture1} alt={row.itemName} style={{ width: '30px' }} />
      ),
      sortable: true,
      width: '8rem',
    },
    // {
    //   name: 'ACTION',
    //   cell: (row) => (
    //     <button className='btn btn-sm btn-danger' data-bs-toggle="modal" data-bs-target="#admindashmodal2" onClick={() => update(row.id)}> Edit</button>
    //   ),
    //   sortable: true,
    //   width: '8rem',
    // },
    {
      name: 'ACTION',
      cell: (row) => (
        <button className='btn btn-sm btn-danger' onClick={() => handleDeleteItem(row.id)}> Delete</button>
      ),
      sortable: true,
      width: '8rem',
    }
    

    ];
      const data=item_info;
      const tableData = {
        columns,
        data
      };

      const update = async (itemid) => {
        const itemID = itemid;
        console.log(itemID)
        const body2 = {
          itemId: itemID
        };
      
        try {
          const response = await axios.post(fetchbyidURL, body2, axiosConfig);
          console.log("response received: ", response);
      
          if (!response) {
            setcust(0);
          } else {
            const sortedArray = response.data.data;
            console.log(sortedArray);
            localStorage.setItem('justgymitemname', response.data.data.itemName);
            localStorage.setItem('justgymitemprice', response.data.data.price);
            localStorage.setItem('justgymitemdesc', response.data.data.itemDescription);
            localStorage.setItem('justgymitemcategory', response.data.data.categoryId);
            localStorage.setItem('justgymnumberOfItems', response.data.data.numberOfItems);
            localStorage.setItem('justgympicture1', response.data.data.picture1);
            localStorage.setItem('itemID', response.data.data.id);
            localStorage.setItem('justgympicture2', response.data.data.picture2);
            localStorage.setItem('justgympicture3', response.data.data.picture3);

            
            // console.log(itemsfetched);

            setState({
              itemnameupdate: response.data.data.itemName,
              priceupdate: response.data.data.price,
              itemID: response.data.data.id,
              itemdescupdate: response.data.data.itemDescription,
              categoryupdate: response.data.data.categoryId,
              numberofitemsupdate: response.data.data.numberOfItems,
              picture1update: response.data.data.picture1,
              picture2update: response.data.data.picture2,
              picture3update: response.data.data.picture3,
            });
          }
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };



      const handleDeleteItem = async (itemId) => {
        try {
            if (!token) {
                return MySwal.fire('Token not available or expired');
            }
    
            const shouldDelete = await MySwal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this item!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            });
    
            if (shouldDelete.isConfirmed) {
                const axiosConfig = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                };
                var body = {
                  "itemId": itemId
              };
    
                const response = await axios.post(deleteURL, body, axiosConfig);
                console.log('Item deleted successfully:', response.data);
                return MySwal.fire('Item deleted successfully');
                window.location.href='items';
            } else {
                return MySwal.fire('Item was not deleted');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
            return MySwal.fire('Error deleting item');
        }
    };
    

    const columns2 = [
      {
              name: 'CUSTOMER NAME',
              selector: (row) => row.firstname,
              sortable: true,
              // width: '1rem',
              cellExport: row => row.firstname 
        
            },
            {
              name: 'CUSTOMER EMAIL',
              selector: (row) => row.email,
              sortable: true,
              // width: "15rem" ,
             cellExport: row => row.email 
          },
          {
            name: 'CUSTOMER PHONE',
            selector: (row) => row.phone,  
            sortable: true,
            // width: "15rem" ,
           cellExport: row => row.phone 
        },
    ];
    const data2=cust_info;
    const itemData = {
      columns2,
      data2
    };
   


      return (
              <div>
     <Header/>
     <div className="account-dashboard pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3">
              {/* Nav tabs */}
              <div className="dashboard_tab_button" data-aos="fade-up" data-aos-delay={0}>
                <ul role="tablist" className="nav flex-column dashboard-list">
                  <li><a href="/admindash"  className="nav-link ">Dashboard</a></li>
                  <li><a href="/items"  className="nav-link active">Items</a></li>
                  <li> <a href="/orders" className="nav-link">Orders</a></li>
                  <li><a href="/customers" className="nav-link">Customers</a></li>
                  <li><a href="#account" data-bs-toggle="tab" className="nav-link">Account details</a>
                  </li>
                  <li><a href="#" onClick={logout}className="nav-link">logout</a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-9 col-lg-9">
              {/* Tab panes */}
              <div className="tab-content dashboard_content" data-aos="fade-up" data-aos-delay={200}>
                <div className="tab-pane fade " id="dashboard">
                  <h4>Dashboard </h4>
                  <div className="row">
                    <div className="col-lg-3">
                        <div className="card cardies">
                        Total Orders: 0
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card cardies2">
                        Total items: 3
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card cardies3">
                        All customers: 0
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card cardies4">
                        Payments: 0
                        </div>
                    </div>
                    </div>

                  {/* <p>From your account dashboard. you can easily check &amp; view your <a href="#">recent
                      orders</a>, manage your <a href="#">shipping and billing addresses</a> and <a href="#">Edit your password and account details.</a></p> */}
                </div>
                <div className="tab-pane fade" id="orders">
                  <h4>Orders</h4>
                  <div className="table_page table-responsive">
                    {/* <table>
                    {cust_info == null ? <Spinner animation="border" /> : ""}
                    <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                    </table> */}
                  </div>
                </div>
                <div className="tab-pane fade show active" id="items">
                  <h4>Items</h4>
                  <button className="btn btn-primary btn-sm" style={{float: 'right'}} type="submit" data-bs-toggle="modal" data-bs-target="#admindashmodal">New Item</button>
                  {/* <button className="btn btn-primary btn-sm" style={{float: 'right'}} data-bs-toggle="modal" data-bs-target="#admindashmodal2">New Item</button> */}
                  <div className="table_page table-responsive">
                 
                    <table>
                    {cust_info == null ? <Spinner animation="border" /> : ""}
                    <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                    </table>
                  </div>
                </div>
               
                <div className="tab-pane fade" id="account">
                  <h3>Account details </h3>
                  <div className="login">
                    <div className="login_form_container">
                      <div className="account_login_form">
                        <form action="#">
                          {/* <p>Already have an account? <a href="#" data-bs-toggle="modal" data-bs-target="#loginActive">Log in instead!</a></p> */}
                          <br />
                          <div className="default-form-box mb-20">
                            <label>First Name</label>
                            <input type="text" name="first-name" disabled value={'admin'} />
                          </div>
                          <div className="default-form-box mb-20">
                            <label>Last Name</label>
                            <input type="text" name="last-name" disabled value={'user'} />
                          </div>
                          <div className="default-form-box mb-20">
                            <label>Email</label>
                            <input type="text" name="email-name" disabled value={'admin@gmail.com'} />
                          </div>
                          <br />
                          <br />
                          {/* <div className="save_button mt-3">
                            <button className="btn" type="submit">Save</button>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal popup-login-style" id="admindashmodal">
          <button type="button" className="close-btn" data-bs-dismiss="modal"><span aria-hidden="true">×</span></button>
          <div className="modal-overlay">
            <div className="modal-dialog p-0" role="document">
              <div className="modal-content" style={{backgroundColor: '#f1f6fc !important'}}>
                <div className="modal-body">
                  <div className="login-content">
                    <h2 style={{color: 'grey'}}>Create Item</h2>
                    <h3 style={{color: 'grey'}}>Create new item for homepage</h3>
                    <form action="#" encType="multipart/form-data">
                      <input type="text" value={state.itemname} onChange={handleChange} id="itemname" name="itemname" placeholder="item name" />
                      <textarea type="text" value={state.itemdesc} onChange={handleChange} id="itemdesc" name="itemdesc" placeholder="description" /><br/><p></p>
                      <input type="number" value={state.price} onChange={handleChange} id="price" name="price" placeholder="item price" /><br/>
                      <select value={state.category} onChange={handleChange} id="category" name="category" placeholder="item category">
                        <option value={''}> Select category</option>
                        <option value={'1'}> MEN</option>
                        <option value={'2'}> WOMEN</option>
                        <option value={'3'}> ACCESSORIES</option>
                        <option value={'4'}> SALE</option>
                      </select>
                      <input type="text" value={state.numberofitems} onChange={handleChange} id="numberofitems" name="numberofitems" placeholder="number of items" />
                      <input type="file" onChange={(event) => handleFileChange(event, 'picture1')} id="state.picture1" name="state.picture1" placeholder="upload"/>
                      <input type="file"  onChange={(event) => handleFileChange(event, 'picture2')} id="state.picture2" name="state.picture2" placeholder="upload" />
                      <input type="file" onChange={(event) => handleFileChange(event, 'picture3')} id="state.picture3" name="state.picture3" placeholder="upload" />
                    
                      <button onClick={handlecreateitem}>Upload</button>
            
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal popup-login-style" id="admindashmodal2">
          <button type="button" className="close-btn" data-bs-dismiss="modal"><span aria-hidden="true">×</span></button>
          <div className="modal-overlay">
            <div className="modal-dialog p-0" role="document">
              <div className="modal-content" style={{backgroundColor: '#f1f6fc !important'}}>
                <div className="modal-body">
                  <div className="login-content">
                    <h2 style={{color: 'grey'}}>Create Item</h2>
                    <h3 style={{color: 'grey'}}>Create new item for homepage</h3>
                    <form action="#" encType="multipart/form-data">
                      <label>Item Name</label>
                      <input type="text" value={state.itemnameupdate} onChange={handleupdateChange} id="itemnameupdate" name="itemnameupdate" placeholder="item name" />
                      <br/>
                      <label>Item Description</label>
                      <textarea type="text" value={state.itemdescupdate} onChange={handleChange} id="itemdescupdate" name="itemdescupdate" placeholder="description" /><br/><p></p>
                      <label>Item Price</label>
                      <input type="number" value={state.priceupdate} onChange={handleChange} id="priceupdate" name="priceupdate" placeholder="item price" /><br/>
                      <label>Item Category</label>
                      <select value={state.categoryupdate} onChange={handleChange} id="categoryupdate" name="categoryupdate" placeholder="item category">
                        <option value={''}> Select category</option>
                        <option value={'1'}> MEN</option>
                        <option value={'2'}> WOMEN</option>
                        <option value={'3'}> ACCESSORIES</option>
                      </select>
                      <label> Number of Items</label>
                      <input type="text" value={state.numberofitemsupdate} onChange={handleChange} id="numberofitemsupdate" name="numberofitemsupdate" placeholder="number of items" />
                      <label>Number of Items</label>
                      <br/>
                      <div className='row'>
                        <div className='col-lg-4'>
                        <a><img src={state.picture1update} width={100}/></a>
                        </div>
                        <div className='col-lg-8'>
                          <label style={{color: 'red'}}>click below to update image</label>
                         <input type="file" onChange={(event) => handleFileChange(event, 'picture1update')} id="state.picture1update" name="state.picture1update" placeholder="upload"/>
                       </div>
                      </div>
                      <br/>
                      <div className='row'>
                        <div className='col-lg-4'>
                        <a><img src={state.picture2update} width={100}/></a>
                        </div>
                        <div className='col-lg-8'>
                          <label style={{color: 'red'}}>click below to update image</label>
                         <input type="file" onChange={(event) => handleFileChange(event, 'picture2update')} id="state.picture2update" name="state.picture2update" placeholder="upload"/>
                       </div>
                      </div>
                      <br/>
                      <div className='row'>
                        <div className='col-lg-4'>
                        <a><img src={state.picture3update} width={100}/></a>
                        </div>
                        <div className='col-lg-8'>
                          <label style={{color: 'red'}}>click below to update image</label>
                         <input type="file" onChange={(event) => handleFileChange(event, 'picture3update')} id="state.picture3update" name="state.picture3update" placeholder="upload"/>
                       </div>
                      </div>
                      <br/>
                      <br/>
                     <button style={{width: '200px'}} onClick={handleUpdateItem}>Update</button>
                     <br/>
                     <br/>
            
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
     <Footer/>
              </div>
               
        );
 
  };

export default Items;
