import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';


function Errorpage(){
 const mydata = localStorage.getItem('')
  const baseUrl = "https://justgymbackend.starlightsoftware.co.bw/api/createOrder";
  const MySwal = withReactContent(Swal)
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const PostData = localStorage.getItem('justgympostdataorder');
  const storedPostData = JSON.parse(PostData);





const createOrder = async () => {
          try {
            Swal.fire({
              text: "Payment unsuccessful, Please Try again",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "Back to checkout",
              closeOnCancel: true,
            }).then(() => {
              window.location.href = 'checkout';
            });
          } catch (error) {
            console.log("AXIOS ERROR:", error);
            Swal.fire(error.message || "An error occurred");
          }
        }

useEffect(() => {
    createOrder();
  }, []);




  
  

      return (
     <div>
     <Header/>
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Errorpage;
